<template>
  <div>
    <div class="cont-main" v-loading="loadings">
      <!-- 头部 -->
      <div class="title flex-start">
        <div class="jniop"></div>
        <div class="juliss">身体状况</div>
      </div>

      <!-- 输入框部分 -->
      <el-form ref="ruleFormRef" :model="ruleForm" label-width="104px" label-position="right" :inline="true"
        class="demo-ruleForm col-333 lable-104">
        <el-form-item label="入室方式：">
          <el-select clearable v-model="ruleForm.dict_enter_department_way" placeholder="请选择入室方式">
            <el-option v-for="item in dictList[180000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自理能力：">
          <el-select clearable v-model="ruleForm.dict_self_care_ability" placeholder="请选择自理能力">
            <el-option v-for="item in dictList[182000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食欲：">
          <el-select clearable v-model="ruleForm.dict_appetite" placeholder="请选择食欲">
            <el-option v-for="item in dictList[167000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="睡眠：">
          <el-select clearable v-model="ruleForm.dict_sleep" placeholder="请选择睡眠">
            <el-option v-for="item in dictList[167000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="精神状态：">
          <el-select clearable v-model="ruleForm.dict_spirit_state" placeholder="请选择精神状态">
            <el-option v-for="item in dictList[183000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="大便次数：">
          <el-input style="width: 50%;" clearable v-model="ruleForm.defecate" placeholder="请输入大便次数">
            <template #append>次</template>
          </el-input>
          <el-input style="width: 50%;" clearable v-model="ruleForm.defecate_day" placeholder="请输入大便次数">
            <template #append>日</template>
          </el-input>
        </el-form-item>
        <el-form-item label="腹泻：">
          <el-select clearable v-model="ruleForm.lax_flag" placeholder="请选择">
            <el-option v-for="item in dictList[184000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="腹泻性状：">
          <el-select clearable v-model="ruleForm.dict_lax_state" placeholder="请选择">
            <el-option v-for="item in dictList[185000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="便秘：">
          <el-select clearable v-model="ruleForm.constipation_flag" placeholder="请选择">
            <el-option v-for="item in dictList[186000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出血：">
          <el-select clearable @change="
              (val) => {
                estimateChange(val, 'bleeding')
              }
            " v-model="ruleForm.bleeding_flag" placeholder="请选择">
            <el-option v-for="item in dictList[187000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出血部位：">
          <el-input clearable :disabled="disabledData.position" v-model="ruleForm.bleeding_position" placeholder="请输入">
          </el-input>
        </el-form-item>
        <el-form-item label="疼痛评估：">
          <el-select clearable v-model="ruleForm.dict_pain" placeholder="请选择">
            <el-option v-for="item in dictList[188000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下肢浮肿：">
          <el-select clearable v-model="ruleForm.xia_zhi_fu_zhong_flag" placeholder="请选择">
            <el-option v-for="item in dictList[400000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跌倒评估：">
          <el-select clearable v-model="ruleForm.fall_flag" placeholder="请选择">
            <el-option v-for="item in dictList[189000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=""></el-form-item>
        <el-form-item class="addr" label="其他状况：">
          <el-input clearable v-model="ruleForm.other_conditions" type="textarea" :rows="2"
            placeholder="请输入其他状况"></el-input>
        </el-form-item>
      </el-form>

      <div class="title flex-start" v-if="ruleForm.type === 1 || ruleForm.type === 2">
        <div class="jniop"></div>
        <div class="juliss">
          {{ ruleForm.type === 1 ? '穿刺评估' : '导管评估' }}
        </div>
      </div>
      <el-form ref="ruleForm1Ref" :model="ruleForm" :rules="Rule.ESTIMATE" label-width="130px" label-position="right"
        :inline="true" class="demo-ruleForm col-333 lable-104" v-if="ruleForm.type === 1 || ruleForm.type === 2">
        <el-form-item>
          <template #label>
            {{ ruleForm.type === 1 ? '穿刺位置：' : '导管位置：' }}
          </template>
          <el-select clearable v-model="ruleForm.dict_position" placeholder="请选择">
            <el-option v-for="item in dictList[11012100]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="外置长度：">
          <el-input clearable v-model="ruleForm.length" placeholder="请输入外置长度">
            <template #append>cm</template>
          </el-input>
        </el-form-item>
        <el-form-item label="血肿：">
          <el-select clearable v-model="ruleForm.xuezhong_flag" placeholder="请选择">
            <el-option v-for="item in dictList[190000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渗血：">
          <el-select clearable v-model="ruleForm.dict_shenxue" placeholder="请选择">
            <el-option v-for="item in dictList[191000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="感染：">
          <el-select clearable v-model="ruleForm.ganran_flag" placeholder="请选择">
            <el-option v-for="item in dictList[192000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="脱管：">
          <el-select clearable v-model="ruleForm.tuo_guan_feng_xian_flag" placeholder="请选择">
            <el-option v-for="item in dictList[193000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="牢固：">
          <el-select v-model="ruleForm.lao_gu_flag" placeholder="请选择">
            <el-option
              v-for="item in dictList[194000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="固定：">
          <el-select v-model="ruleForm.gu_ding_flag" placeholder="请选择">
            <el-option
              v-for="item in dictList[195000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="动脉端：">
          <el-select v-model="ruleForm.dict_dong_mai_duan" placeholder="请选择">
            <el-option
              v-for="item in dictList[196000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="静脉端：">
          <el-select v-model="ruleForm.dict_jing_mai_duan" placeholder="请选择">
            <el-option
              v-for="item in dictList[197000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="动脉端封管液量：">
          <el-input clearable v-model="ruleForm.dongmai_val" placeholder="请输入">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
        <el-form-item label="静脉端封管液量：">
          <el-input clearable v-model="ruleForm.jingmai_val" placeholder="请输入">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
      </el-form>

      <div class="title flex-start" v-if="ruleForm.type === 3">
        <div class="jniop"></div>
        <div class="juliss">内瘘评估</div>
      </div>
      <el-form ref="ruleForm2Ref" :model="ruleForm" :rules="Rule.ESTIMATE" label-width="104px" label-position="right"
        :inline="true" class="demo-ruleForm col-333 lable-104" v-if="ruleForm.type === 3">
        <el-form-item label="内瘘位置：" prop="dict_position">
          <el-select clearable v-model="ruleForm.dict_position" placeholder="请选择">
            <el-option v-for="item in dictList[11015000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="成熟训练：">
          <el-select
            @change="
              (val) => {
                estimateChange(val, 'training')
              }
            "
            v-model="ruleForm.mature_training"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList[199000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="训练频次：">
          <el-input
            :disabled="disabledData.frequency"
            v-model="ruleForm.training_frequency"
            placeholder="请输入"
          >
            <template #append>次/日</template>
          </el-input>
        </el-form-item> -->
        <el-form-item label="内瘘通路：">
          <el-select clearable v-model="ruleForm.dict_neiloutonglu" placeholder="请选择">
            <el-option v-for="item in dictList[902000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="堵塞：">
          <el-select clearable v-model="ruleForm.blocking" placeholder="请选择">
            <el-option v-for="item in dictList[198000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="震颤：">
          <el-select clearable v-model="ruleForm.tremor" placeholder="请选择">
            <el-option v-for="item in dictList[200000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="杂音：">
          <el-select v-model="ruleForm.noise" placeholder="请选择">
            <el-option
              v-for="item in dictList[228000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="动脉端：">
          <el-select v-model="ruleForm.dict_dong_mai_duan" placeholder="请选择">
            <el-option
              v-for="item in dictList[196000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="静脉端：">
          <el-select v-model="ruleForm.dict_jing_mai_duan" placeholder="请选择">
            <el-option
              v-for="item in dictList[197000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="A端距离瘘口">
          <el-input v-model="ruleForm.a_distance" placeholder="请输入">
            <template #append>cm</template>
          </el-input>
        </el-form-item>
        <el-form-item label="V端距离瘘口">
          <el-input v-model="ruleForm.v_distance" placeholder="请输入">
            <template #append>cm</template>
          </el-input>
        </el-form-item> -->
        <el-form-item label="穿刺点A：">
          <!-- <el-select clearable v-model="ruleForm.a_puncture" placeholder="请选择">
            <el-option v-for="item in dictList[229000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select> -->
          <el-radio-group v-model="ruleForm.a_puncture">
            <el-radio v-for="item in dictList[229000000]" :key="item.id" :label="item.code"
              :value="item.code">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="穿刺点V：">
          <el-radio-group v-model="ruleForm.v_puncture">
            <el-radio v-for="item in dictList[229000000]" :key="item.id" :label="item.code"
              :value="item.code">{{item.name}}</el-radio>
          </el-radio-group>
          <!-- <el-select clearable v-model="ruleForm.v_puncture" placeholder="请选择">
            <el-option v-for="item in dictList[229000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="血肿：">
          <el-select clearable v-model="ruleForm.xuezhong_flag" placeholder="请选择">
            <el-option v-for="item in dictList[190000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="血肿大小：">
          <el-select clearable v-model="ruleForm.xuezhong_size" placeholder="请选择">
            <el-option v-for="item in dictList[229000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渗血：">
          <el-select clearable v-model="ruleForm.dict_shenxue" placeholder="请选择">
            <el-option v-for="item in dictList[191000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="感染：">
          <el-select clearable v-model="ruleForm.ganran_flag" placeholder="请选择">
            <el-option v-for="item in dictList[192000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="血管弹性：">
          <el-select v-model="ruleForm.blood_elasticity" placeholder="请选择">
            <el-option
              v-for="item in dictList[230000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </div>

    <!-- 保存按钮 -->
    <div class="warings" @click="estimateSave">保存</div>
  </div>
</template>

<script>
  import { reactive, toRefs, onMounted, watch } from 'vue'
  import Rule from '@/utils/rule'
  import service from '@/utils/request'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { gitDictList } from '@/utils/tool'
  export default {
    props: ['patientData'],
    emits: ['stepsUpdate'],
    setup(props, ctx) {
      const state = reactive({
        dictList: {},
        ruleForm: {
          type: 3,
        },
        loadings: false,
        Rule: Rule,
        ruleForm1Ref: null,
        ruleForm2Ref: null,
        disabledData: {
          position: true,
          frequency: true,
        },
        searchData: {
          patient_id: 0,
          record_id: 0,
        },
      })

      watch(
        () => props.patientData.patient_id,
        (val) => {
          if (val) {
            state.searchData = {
              patient_id: val,
              record_id: props.patientData.record_id,
            }
          } else {
            state.searchData = {
              patient_id: 0,
              record_id: 0,
            }
          }
          getDataList()
        }
      )

      onMounted(() => {
        if (props.patientData.patient_id) {
          state.searchData = {
            patient_id: props.patientData.patient_id,
            record_id: props.patientData.record_id,
          }
        } else {
          state.searchData = {
            patient_id: 0,
            record_id: 0,
          }
        }
        getDict()
        setTimeout(() => {
          getDataList()
        }, 1000)

      })
      const getDataList = async () => {
        state.loadings = true
        let res = await service.post('api/estimate/list', state.searchData)
        state.loadings = false
        if (res.code === 0) {
          state.ruleForm = res.data
        }
      }
      const getDict = async () => {
        state.dictList = await gitDictList([
          '180000000',
          '182000000',
          '167000000',
          '400000000',
          '183000000',
          '184000000',
          '185000000',
          '186000000',
          '187000000',
          '188000000',
          '189000000',
          '190000000',
          '191000000',
          '192000000',
          '193000000',
          '194000000',
          '195000000',
          '196000000',
          '197000000',
          '198000000',
          '199000000',
          '200000000',
          '228000000',
          '229000000',
          '230000000',
          '11015000',
          '11012100',
          '902000000'
        ])
      }
      const estimateSave = async () => {
        if (!state.searchData.patient_id) {
          ElMessageBox.alert('请先选择患者', '提示', {
            confirmButtonText: '确认',
          })
          return
        }
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        if (state.ruleForm.type == 3 && !state.ruleForm.dict_position) {
          ElMessage({
            message: '内瘘位置必填',
            type: 'warning',
          })
          return
        } else {
          state.ruleForm.patient_id = state.searchData.patient_id
          state.ruleForm.record_id = state.searchData.record_id
          let res = await service.post('api/estimate/create', state.ruleForm)
          if (res.code === 0) {
            ElMessage.success(res.msg)
            ctx.emit('stepsUpdate')
          }
        }
      }
      // 下拉二级联动
      const estimateChange = (val, type) => {
        switch (type) {
          case 'bleeding':
            if (val === '187120000') {
              state.disabledData.position = false
            } else {
              state.disabledData.position = true
            }
            break
          case 'training':
            if (val === '199110000') {
              state.disabledData.frequency = false
            } else {
              state.disabledData.frequency = true
            }
            break
        }
      }
      return {
        ...toRefs(state),
        estimateSave,
        estimateChange,
        getDataList,
      }
    },
  }
</script>

<style scoped lang="scss">
  .cont-main {
    flex: 1;

    // max-height: 600px;
    // overflow-y: auto;
    // overflow-x: hidden;
    .el-form {
      border-bottom: 1px solid #e8e8e8;
      margin: 16px 0;
    }

    .el-form-item {
      width: 30%;
      margin-right: 5%;

      &.addr {
        width: 100%;
        margin-right: 0;
      }
    }

    .el-form-item:nth-child(3n + 3) {
      margin-right: 0;
    }

    .userItem {
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 30px;

      .user-tit {
        font-size: 24px;
        font-family: 'Source Han Sans CN-Medium';
        color: #333333;
        border-left: 6px solid #3166ae;
        padding: 0 18px;
        margin-bottom: 40px;
      }
    }
  }

  .title {
    margin-bottom: 8px;

    .jniop {
      width: 6px;
      height: 18px;
      background: #3166ae;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      margin-top: 4px;
      margin-right: 10px;
    }

    .juliss {
      height: 18px;
      font-size: 18px;
      color: #333333;
      margin-left: 10px;
    }
  }

  .warings {
    position: fixed;
    bottom: 8%;
    right: 5%;
    width: 60px;
    height: 60px;
    background-color: #0057bb;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    line-height: 60px;
    margin: 0 20px;
    cursor: pointer;
  }
</style>