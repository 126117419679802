<template>
  <div>
    <!-- 临时医嘱 -->
    <div class="hande">
      <div class="Dialysis">
        <!-- 头部 -->
        <div class="jniop"></div>
        <!-- <div class="juliss">签名</div> -->
        <!-- <div style="display: flex; justify-content: space-between;">
          <el-form ref="ruleFormRef" :model="create" :rules="rules" :inline="true" class="demo-ruleForm">
            <el-row>
              <el-col :span="24">
                <el-form-item label="冲管者：">
                  <el-select clearable v-model="create.cgz_id" class="m-2" placeholder="请选择">
                    <el-option v-for="item in userInfoCGZ" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-button v-if="create.cgz_id==''||create.cgz_id==null||create.cgz_id==undefined" class="color-main" type="primary" @click="changeValue('cgz_id','cgz_id_name','1')">
                  确认冲管者
                </el-button>
                <el-button v-else class="color-main" type="primary" @click="changeValue('cgz_id','cgz_id_name','2')">
                  取消冲管者
                </el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="穿刺者：">
                  <el-select clearable v-model="create.ccz_id" class="m-2" placeholder="请选择">
                    <el-option v-for="item in userInfoCCZ" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-button v-if="create.ccz_id==''||create.ccz_id==null||create.ccz_id==undefined" class="color-main" type="primary" @click="changeValue('ccz_id','ccz_id_name',1)">
                  确认穿刺者
                </el-button>
                <el-button v-else class="color-main" type="primary" @click="changeValue('ccz_id','ccz_id_name','2')">
                  取消穿刺者
                </el-button>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="引血者：">
                  <el-select clearable v-model="create.jxz_id" class="m-2" placeholder="请选择" >
                    <el-option v-for="item in userInfoJXZ" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-button v-if="create.jxz_id==''||create.jxz_id==null||create.jxz_id==undefined" class="color-main" type="primary" @click="changeValue('jxz_id','jxz_id_name',1)">
                  确认引血者
                </el-button>
                <el-button v-else class="color-main" type="primary" @click="changeValue('jxz_id','jxz_id_name','2')">
                  取消引血者
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div> -->
      </div>
      <div style="display: flex; justify-content: space-between;">
        <div>
          <el-form :inline="true">
            <el-form-item label="">
              <el-checkbox v-model="record.is_on" @change="setIsOn">上机</el-checkbox>
            </el-form-item>
            <el-form-item label="上机时间">
              <el-date-picker @change="setSjzTime" v-model="record.sjz_time" type="datetime" format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm" placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="上机者">
              <el-select clearable v-model="record.sjz_id_name" placeholder="请选择" @change="setSjz">
                <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form :inline="true" class="">
            <el-form-item label="">
              <el-checkbox v-model="record.is_check" @change="setIsCheck">上机核对</el-checkbox>
            </el-form-item>
            <el-form-item label="核对者">
              <el-select clearable v-model="record.hdz_id_name" placeholder="请选择" @change="setHdz">
                <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form :inline="true" class="">
            <el-form-item label="">
              <el-checkbox v-model="record.is_off" @change="setIsOff">下机</el-checkbox>
            </el-form-item>
            <el-form-item label="下机时间">
              <el-date-picker @change="setXjzTime" v-model="record.xjz_time" type="datetime" format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm" placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="下机者">
              <el-select clearable v-model="record.xjz_id_name" placeholder="请选择" @change="setXjz">
                <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>

        </div>

        <div class="">
          <el-button class="color-main" type="primary" @click="addTesting">
            <i class="fa fa-plus-circle"></i>新增
          </el-button>
        </div>
      </div>



      <!-- 表格部分 -->
      <div style="margin-top: 10px">
        <el-table class="sss" :data="tableData" size="small" stripe v-loading="loading">
          <el-table-column prop="record_time" width="170" label="记录时间" align="center" />

          <el-table-column width="100" label="收缩压" align="center">
            <el-table-column prop="shousuoya" width="100" label="(mmHg)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="舒张压" align="center">
            <el-table-column prop="shuzhangya" width="100" label="(mmHg)" align="center">
            </el-table-column>
          </el-table-column>

          <el-table-column width="100" label="脉搏/心率" align="center">
            <el-table-column prop="xinlv" width="100" label="(次/min)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="呼吸" align="center">
            <el-table-column prop="diandao" width="100" label="(次/min)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="体温" align="center">
            <el-table-column prop="tiwen" width="100" label="(°C)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="血流量" align="center">
            <el-table-column prop="dxueliuliang" width="100" label="(ml/mi)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="动脉压" align="center">
            <el-table-column prop="dongmaiya" width="100" label="(mmHg)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="静脉压" align="center">
            <el-table-column prop="jingmaiya" width="100" label="(mmHg)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="跨膜压" align="center">
            <el-table-column prop="kuamoya" width="100" label="(mmHg)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="超滤率" align="center">
            <el-table-column prop="chaolvlv" width="100" label="(ml/h)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="超滤量" align="center">
            <el-table-column prop="chaolvliang" width="100" label="(ml)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="置换液" align="center">
            <el-table-column prop="zhihuanye" width="100" label="(L)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column width="100" label="钠浓度" align="center">
            <el-table-column prop="nanongdu" width="100" label="(mol/L)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column prop="jiwen" width="80" label="机温(℃)" align="center" />
          <el-table-column width="100" label="脱水量" align="center">
            <el-table-column prop="target_water" width="100" label="(ml)" align="center">
            </el-table-column>
          </el-table-column>
          <!-- <el-table-column prop="chanci" width="160" label="穿刺(置管)渗血、血肿" align="center" /> -->
          <el-table-column width="100" label="进液量" align="center">
            <el-table-column prop="jinyeliang" width="100" label="(ml)" align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column prop="zhengzhuang" width="140" show-overflow-tooltip label="症状" align="center" />
          <el-table-column prop="chuli" width="140" show-overflow-tooltip label="处理" align="center" />
          <el-table-column prop="nurse.name" label="护士签名" align="center" />
          <el-table-column fixed="right" label="操作" width="100">
            <template #default="scope">
              <div class="Dsefault">
                <div class="Colum" @click="modParient(scope.row)" style="background: #3166ae">
                  改
                </div>
                <span class="Colum" @click="delParient(scope.row)"
                  style="background: rgba(255, 62, 62, 0.1); color: #ff3e3e">
                  删
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页 -->
      <div class="pagination-box">
        <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum" @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <Add :patientData="patientData" ref="addRef" @refreshDataList="refreshData"></Add>
  </div>
</template>

<script>
  import service from '@/utils/request'
  import { reactive, toRefs, watch, onMounted, nextTick } from 'vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import Add from './add.vue'

  export default {
    components: {
      Add,
    },
    props: {
      patientData: {
        type: Object,
        required: true,
      },
    },
    emits: ['stepsUpdate'],
    setup(props, ctx) {
      const state = reactive({
        formData: {},
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        addRef: null,
        loading: true,
        searchData: {},
        dataListLoading: false,
        tableData: [],
        userList: [],
        record: {
          is_on: false,
          is_off: false,
          is_check: false
        },
        userInfo: [],
        userInfoCCZ: [],
        userInfoJXZ: [],
        userInfoCGZ: [],
        create: {
        },
      })

      watch(
        () => props.patientData.patient_id,
        (val) => {
          if (val) {
            state.searchData = {
              patient_id: val,
              record_id: props.patientData.record_id,
            }
          } else {
            state.searchData = {
              patient_id: 0,
              record_id: 0,
            }
          }
          getDataList()
          getOnInfo()
          getUserList()
          getQianMing()
        }
      )

      onMounted(() => {
        initData()
      })

      const initData = () => {
        if (props.patientData.patient_id) {
          state.searchData = {
            patient_id: props.patientData.patient_id,
            record_id: props.patientData.record_id,
          }
        } else {
          state.searchData = {
            patient_id: 0,
            record_id: 0,
          }
        }
        getDataList()
        getOnInfo()
        getUserList()
        getUserListQianMing()
        getQianMing()
      }
      const getUserList = async () => {
        let res = await service.post('/api/user/get_current_user')
        if (res.code === 0) {
          state.userList = res.data.list
        }
      }
      //签名的话只能时登录当前人
      const getUserListQianMing = async () => {
        state.userInfo = [JSON.parse(localStorage.getItem('ms_userData'))]
        console.log(state.userInfo)
        // let res = await service.post('/api/user/get_all_nurse')
        // if (res.code === 0) {
        //   state.userInfo = res.data.list
        // }
      }
      const changeValue = (code,name,type) => {
         console.log(code,state.userInfo)
         if(type==1){
          state.create[code]=state.userInfo[0].id
          state.create[name]=state.userInfo[0].name
         }else{
          state.create[code]=''
          state.create[name]=''
         }
         console.log(111, state.create)
         conserve()
      }
      const conserve = async () => {
        console.log(state.searchData)
        state.create.patient_id = state.searchData.patient_id
        state.create.record_id = state.searchData.record_id
        if (state.create.record_id == 0) {
          return
        }
        
        const res = await service.post(
          '/api/dialysis_record/save_sign',
          state.create
        )
        if (res.code === 0) {
          ElMessage.success('保存成功')
          // this.stepsUpdate()
          getQianMing()
          // // this.listIndex = 0
          // this.user()
        }
      }
      //获取签名信息
      const getQianMing = async () => {
        console.log(state.searchData)
        state.userInfoCCZ = []
        state.userInfoCGZ = []
        state.userInfoJXZ = []
        state.create.record_id = state.searchData.record_id
        const res = await service.post('/api/dialysis_record/get_sign_info', state.create)
        if (res.code === 0) {
          if (res.data.length == 0) {
            state.create = {}
            state.userInfoCCZ = state.userInfo
            state.userInfoCGZ = state.userInfo
            state.userInfoJXZ = state.userInfo
          } else {
            state.create = res.data
            console.log(state.create, state.create.ccz_id, state.userInfo[0].id)
            if (state.create.ccz_id !== '' && state.create.ccz_id != state.userInfo[0].id && state.create.hasOwnProperty("ccz_id")) {
              let obj = {
                name: state.create.ccz_id_name,
                id: state.create.ccz_id
              }
              state.userInfoCCZ.push(obj, ...state.userInfo)
            } else {
              state.userInfoCCZ = state.userInfo
            }
            if (state.create.cgz_id !== '' && state.create.cgz_id != state.userInfo[0].id && state.create.hasOwnProperty("cgz_id")) {
              let obj = {
                name: state.create.cgz_id_name,
                id: state.create.cgz_id
              }
              state.userInfoCGZ.push(obj, ...state.userInfo)
            } else {
              state.userInfoCGZ = state.userInfo
            }
            if (state.create.jxz_id !== '' && state.create.jxz_id != state.userInfo[0].id && state.create.hasOwnProperty("jxz_id")) {
              let obj = {
                name: state.create.jxz_id_name,
                id: state.create.jxz_id
              }
              state.userInfoJXZ.push(obj, ...state.userInfo)
            } else {
              state.userInfoJXZ = state.userInfo
            }
            console.log(state.userInfo)
            console.log(state.create)
          }
        }
      }
      //上机
      const setIsOn = async (e) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        let res = await service.post('/api/dialysis_record/setOn', {
          record_id: state.searchData.record_id,
          is_on: e
        })
        if (res.code !== 0) {
          ElMessage.error('修改失败')
        }
        getOnInfo()
        refreshData()
        console.log(state)
      }
      //下机
      const setIsOff = async (e) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        let res = await service.post('/api/dialysis_record/setOff', {
          record_id: state.searchData.record_id,
          is_off: e
        })
        if (res.code !== 0) {
          ElMessage.error('修改失败')
        }
        getOnInfo()
        refreshData()
      }
      // 核对
      const setIsCheck = async (e) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        let res = await service.post('/api/dialysis_record/setCheck', {
          record_id: state.searchData.record_id,
          is_check: e
        })
        if (res.code !== 0) {
          ElMessage.error('修改失败')
        }
        getOnInfo()
        refreshData()
      }
      //设置上机时间
      const setSjzTime = async (e) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        let res = await service.post('/api/dialysis_record/setSjzTime', {
          record_id: state.searchData.record_id,
          sjz_time: e
        })
        if (res.code !== 0) {
          ElMessage.error('修改失败')
        }
        getOnInfo()
        refreshData()
      }
      //设置下机时间
      const setXjzTime = async (e) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        let res = await service.post('/api/dialysis_record/setXjzTime', {
          record_id: state.searchData.record_id,
          xjz_time: e
        })
        if (res.code !== 0) {
          ElMessage.error('修改失败')
        }
        getOnInfo()
        refreshData()
      }
      //设置上机者
      const setSjz = async (e) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        let res = await service.post('/api/dialysis_record/setSjz', {
          record_id: state.searchData.record_id,
          sjz_id: e
        })
        if (res.code !== 0) {
          ElMessage.error('修改失败')
        }
        getOnInfo()
      }
      //设置下机者
      const setXjz = async (e) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        let res = await service.post('/api/dialysis_record/setXjz', {
          record_id: state.searchData.record_id,
          xjz_id: e
        })
        if (res.code !== 0) {
          ElMessage.error('修改失败')
        }
        getOnInfo()
      }
      //设置核对
      const setHdz = async (e) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        let res = await service.post('/api/dialysis_record/setHdz', {
          record_id: state.searchData.record_id,
          hdz_id: e
        })
        if (res.code !== 0) {
          ElMessage.error('修改失败')
        }
        getOnInfo()
      }
      //获取上机信息
      const getOnInfo = async () => {
        if (!state.searchData.record_id) {
          state.record = {}
          state.record.is_check = false
          state.record.is_off = false
          state.record.is_on = false
          return
        }
        let res = await service.post('/api/dialysis_record/getOnInfo', { record_id: state.searchData.record_id })
        if (res.code === 0) {
          state.record = res.data
        }
      }

      const refreshData = () => {
        getDataList()
        getQianMing()
        ctx.emit('stepsUpdate')
      }
      const getDataList = async () => {
        state.loading = true
        state.searchData.current = state.pageData.currentPage
        state.searchData.size = state.pageData.pageSize
        state.dataListLoading = true
        let res = await service.post('/api/estimate/monitor', state.searchData)
        state.dataListLoading = false
        if (res.code === 0) {
          state.tableData = res.data.records
          state.pageData.totalSum = res.data.total
          state.loading = false
        }
      }
      const handleSizeChange = (val) => {
        state.pageData.pageSize = val
        getDataList()
      }

      const handleCurrentChange = (val) => {
        state.pageData.currentPage = val
        getDataList()
      }
      // 新增弹窗确认
      const addTesting = () => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        nextTick(() => {
          state.addRef.initData(state.searchData, '', state.record.sjz_time)
        })
      }
      // 删除记录
      const delParient = (item) => {
        if (!state.searchData.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能保存透前评估！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await service.post('api/estimate/deleteMonitor', {
              id: item.id,
              patient_id: state.searchData.patient_id,
              record_id: state.searchData.record_id,
            })
            if (res.code === 0) {
              ElMessage.success(res.msg)
              getDataList()
              ctx.emit('stepsUpdate')
            }
          })
          .catch(() => {
          })
      }
      // 修改
      const modParient = (item) => {
        nextTick(() => {
          state.addRef.initData(state.searchData, item, '')
        })
      }
      return {
        ...toRefs(state),
        handleSizeChange,
        handleCurrentChange,
        addTesting,
        delParient,
        modParient,
        getDataList,
        refreshData,
        setIsOn,
        setIsOff,
        setIsCheck,
        setSjzTime,
        setXjzTime,
        getUserList,
        getUserListQianMing,
        setSjz,
        setXjz,
        setHdz,
        conserve,
        getQianMing,
        changeValue
      }
    },
  }
</script>

<style scoped lang="scss">
  .upButtom {
    text-align: right;
  }

  .hande {
    padding: 10px;
    height: 557px;
    border-radius: 8px;
    opacity: 1;
    border: 1px solid;
    border-image: linear-gradient(180deg,
        rgba(49, 102, 174, 1),
        rgba(49, 102, 174, 0.3),
        rgba(49, 102, 174, 0),
        rgba(49, 102, 174, 0)) 1 1;
  }

  .Dsefault {
    display: flex;
    justify-content: space-around;
  }

  .Colum {
    width: 21px;
    height: 21px;
    background: #f4a939;
    opacity: 1;
    color: #fff;
    font-size: 12px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    line-height: 21px;
  }

  .Dsefault span:hover {
    background: #ff3e3e !important;
    color: #fff !important;
  }

  .juliss {
    width: 97%;
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }
</style>