<template>
  <div class="box">
    <div class="line"></div>
    <div
      v-for="(item, index) in stepsData"
      :key="index"
      :class="['item', item.active ? 'active' : '']"
    >
      <div class="num">
        <div :class="['num2', item.active ? 'active' : '']">
          {{ index + 1 }}
        </div>
      </div>
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TxSteps',
  props: {
    stepsData: {
      type: Array,
    },
    // 进行到当前哪一步
    currentStep: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;

  .line {
    position: absolute;
    border: 1px solid #d4deeb;
    top: 50%;
    width: 100%;
  }

  .active {
    background-color: #409eff !important;
    color: #fff !important;
  }

  .item {
    display: flex;
    align-items: center;
    background-color: #d9ecff;
    width: 80px;
    height: 24px;
    border-radius: 12px;
    color: #999999;
    position: relative;
    text-align: center;
    padding-left: 30px;

    .num {
      position: absolute;
      left: -15px;
      width: 30px;
      height: 30px;
      border: 1px solid #d9ecff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
      background-color: #fff;

      .num2 {
        width: 24px;
        height: 24px;
        background-color: #d9ecff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
}
</style>
