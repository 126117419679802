<template>
  <div class="fatherHeight">
    <!-- 步骤条 -->
    <div class="Steps">
      <TxSteps :steps-data="stepsStatusData"></TxSteps>
    </div>
    <div class="hande">
      <!-- 头部第一行 -->
      <div class="flex-between">
        <div class="flex-start">
          <!-- 左侧 -->
          <el-form :inline="true" :model="formInline" class="demo-form-inline search">
            <el-form-item>
              <div class="flex-between">
                <div :class="[is_sign === -1 ? 'Qiando' : '', 'Qiandon']" @click="Qiando(-1)">
                  全部({{ statusCount.all }})
                </div>
                <div :class="[is_sign === 1 ? 'Qiando' : '', 'Qiandon']" @click="Qiando(1)">
                  已签({{ statusCount.sign }})
                </div>
                <div :class="[is_sign === 0 ? 'Qiando' : '', 'Qiandon']" @click="Qiando(0)">
                  未签({{ statusCount.nosign }})
                </div>
              </div>
            </el-form-item>
            <el-form-item label="班次:">
              <el-select v-model="formInline.user" class="m-2" placeholder="请选择" @change="search">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <!-- 姓名 -->
            <el-form-item label="姓名:">
              <el-input v-model="formInline.name" @keydown.enter="search" class="w-50 m-2" placeholder="搜索姓名/首拼">
                <template #suffix>
                  <el-icon class="el-input__icon">
                    <search />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>

            <!-- 时间 -->
            <el-form-item label="时间:">
              <el-date-picker v-model="formInline.time" @change="search" type="date" value-format="YYYY-MM-DD"
                placeholder="请选择时间"></el-date-picker>
            </el-form-item>
            <!-- 院区 -->
            <el-form-item label="院区：">
              <el-select v-model="formInline.Yval" class="m-2" placeholder="请选择">
                <el-option v-for="item in Ylist" :key="item.id" :label="item.name" :value="item.name"
                  @click="hospi(item.id)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>

        <!-- 右侧 -->
        <div style="display: flex; justify-content: space-between; width: 200px">
          <el-button class="color-main" @click="refresh" type="primary">
            <i class="fa fa-refresh"></i>重置
          </el-button>
          <el-button @click="search" class="color-main" type="primary">
            <i class="fa fa-search"></i>查询
          </el-button>
        </div>
      </div>
    </div>
    <!-- 中间内容部分 -->
    <div class="bodycliss">
      <!-- 左侧 -->
      <div class="lifebody">
        <el-scrollbar>
          <div v-if="list.length === 0">
            <el-empty description="暂无数据！"></el-empty>
          </div>

          <div class="zy-bord" :class="[listIndex === index ? 'active' : '']" v-for="(item, index) in list"
            :key="index">
            <div class="userinfo-item">
              <div class="userinfo-item-top flex-between" @click="bodycl(index, item)">
                <div class="nameBox">
                  <div class="name" @click="toHZXQ(item)">{{ item.patient_name }}</div>
                  <div class="clinicalreception" v-if="item.is_sign === 1">
                    {{ item.status_str }}
                  </div>
                </div>
                <div class="pcls" @click="Pclick(item)" v-if="item.is_sign === 0">
                  未签到
                </div>
                <div class="pcls" @click="Delete(item)" v-else>取消签到</div>
              </div>
              <div class="userinfo-item-bottom" @click="bodycl(index, item)">
                <div class="flex-between">
                  <div class="_item">年龄: {{ item.patient.age }}岁</div>
                  <div class="_item">
                    性别:{{ item.patient.dict_sex_name }}性
                  </div>
                </div>
                <div class="flex-between">
                  <div class="_item">床位号:{{ item.bed_num }}</div>
                  <div class="_item">透析方式:{{ item.nm_dialyse_way }}</div>
                </div>
                <div class="_item" v-if="item.record">
                  抗凝剂:{{ item.record.dict_kangning_name }}
                  {{ item.record.kangning_shouji_num }}
                  {{ item.record.dict_kangning_shouji_unit_name }}
                </div>
                <div class="_item" v-if="item.record">
                  目标脱水:{{ item.record.target_warter || '--'}}ml
                </div>
                <div class="_item" v-if="item.record">
                  血管通路:{{ item.record.dict_tonglu_name }}
                </div>
                <div class="_item" v-if="item.dialysis_time">
                  透析时长:{{ item.dialysis_time }}
                </div>
                <div class="_item" v-if="item.record">
                  血透器:{{ item.record.dict_touxiqi_name || '--' }}
                </div>
                <div class="_item" v-if="item.record">干体重：{{item.record.dry_weight||'--'}}kg
                </div>
                <div class="_item" v-if="item.record">
                  透前体重：{{item.record.befor_weight||'--'}}kg</div>
                <div class="_item">
                  透后体重：{{item.after_weight||'--'}}kg</div>
                <div class="_item" v-if="item.record">
                  上机时间：{{item.record.sjz_time||'--'}}</div>
                <div class="_item">
                  <span style="color: #f00" v-if="item.countdown != 0">
                    倒计时:{{ item.countdownZw }}
                  </span>
                  <span class="info-btn" v-if="item.show_off">
                    <img class="logoImg" src="@/assets/img/ions/tanhao.png" />
                    待下机
                  </span>
                </div>
              </div>
              <template v-if="item.colorSet!='aaa'">
                <div class="notice" :class="item.colorSet=='green'?'green':item.colorSet=='red'?'red':''"
                  @click="openRemind(item)">提醒</div>
              </template>
            </div>
          </div>
        </el-scrollbar>
      </div>

      <!-- 右侧 -->
      <div class="righbody">
        <!-- 头部 -->
        <div class="headfixed">
          <div style="display: flex; margin-bottom: 8px" v-if="list.length != 0">
            <div style="font-size: 18px; font-weight: 500; color: #3166ae">
              {{ list[listIndex].patient_name }}
            </div>
            <div class="textcliss">
              {{ list[listIndex].patient.dict_sex_name }}
            </div>
            <div class="textcliss">{{ list[listIndex].patient.age }}岁</div>
            <div class="textcliss" v-if="list[listIndex].tonglu">
              {{ list[listIndex].tonglu.dict_tonglu_type_name }}
            </div>
            <div class="textcliss">
              病案号：{{ list[listIndex].patient_case_no }}
            </div>
          </div>

          <!-- 按钮区 -->
          <div class="hanbut flex-between">
            <div class="hanbuts">
              <div :class="[BtnIndex === index ? 'butnlis' : 'butnli']" v-for="(items, index) in btnlist" :key="index"
                @click="butncls(index)">
                {{ items.name }}
              </div>
            </div>
          </div>
        </div>
        <div id="printArea">
          <div class="printAreaBox">
            <div class="name">医院名称</div>
            <div class="time">{{ printData.time }}</div>
            <div class="print-item">
              <span class="pl">{{ printData.base.name }}</span>
              <span class="pl">{{ printData.base.dict_code }}</span>
              <span class="pl">{{ printData.base.bed_num }}床</span>
            </div>
            <div class="print-item">
              <span class="pl">{{ printData.base.dict_sex_name }}</span>
              <span class="pl">{{ printData.base.age }}岁</span>
            </div>
            <div class="print-item">
              <span class="pl">{{
                printData.base.dict_dialysis_type_name
                }}</span>
              <span class="pl">{{ printData.base.dialysis_hour }}时{{
                printData.base.dialysis_min
                }}分</span>
            </div>
            <div class="print-item">
              抗凝剂：{{ printData.base.dict_kangning_name }}
            </div>
            <div class="print-item">
              首剂：{{
              printData.base.kangning_shouji_num
              }}{{ printData.base.dict_kangning_shouji_unit_name }}
            </div>
            <div class="print-item">
              上次透前体重：{{ printData.base.befor_weight }}kg
            </div>
            <div class="print-item">
              干体重：{{ printData.base.dry_weight }}kg
            </div>
            <div class="print-item">
              体重添长：{{ printData.base.befor_raise_weight }}kg
            </div>
            <div class="print-item">
              血流速：{{ printData.base.xueliu_speed_min }}ml/min
            </div>
            <div class="print-item">
              透析器：{{ printData.base.dict_touxiqi_name }}
            </div>
            <div class="print-item">
              透析液流速：{{ printData.base.touxiye_speed }}ml/min
            </div>
            <div class="print-item">
              通路类型：{{ printData.tonglu.dict_tonglu_type_name }}
            </div>
            <div class="print-item">
              通路部位：{{ printData.tonglu.dict_tonglu_position_name }}
            </div>
            <div class="print-item">
              介入方式：{{ printData.tonglu.dict_intervene_type_name }}
            </div>
            <template v-if="printData.chuanci.is_fistula == '350000000'">
              <div class="print-item">
                A端穿刺方法：{{ printData.chuanci.dict_a_chuanci_way_name }}
              </div>
              <div class="print-item">
                A端穿刺类型：{{ printData.chuanci.dict_a_chuanci_zhen_name }}
              </div>
              <div class="print-item">
                A端穿刺点距吻合口距离：{{ printData.chuanci.a_distance }}cm
              </div>
              <div class="print-item">
                V端穿刺方法：{{ printData.chuanci.dict_v_chuanci_way_name }}
              </div>
              <div class="print-item">
                V端穿刺类型：{{ printData.chuanci.dict_v_chuanci_zhen_name }}
              </div>
              <div class="print-item">
                V端穿刺点距吻合口距离：{{ printData.chuanci.v_distance }}cm
              </div>
              <div class="print-item">
                AtV端两穿刺点间距离：{{ printData.chuanci.atv_distance }}cm
              </div>
            </template>
            <template v-else>
              <div class="print-item">
                穿刺方式：{{ printData.chuanci.dict_chuanci_type_name }}
              </div>
            </template>
            <div class="print-item">K：{{ printData.base.k }}mmol/L</div>
            <div class="print-item">Ca：{{ printData.base.ca }}mmol/L</div>
            <div class="print-item">Na：{{ printData.base.na }}mmol/L</div>
          </div>
        </div>
        <!-- 执行医嘱 -->
        <my-YZ :patientData="actPatientData" :searchListData="formInline" @stepsUpdate="stepsUpdate" ref="adviceRef"
          v-if="BtnIndex === 1"></my-YZ>

        <!-- 透前评估 -->
        <my-PG :patientData="actPatientData" @stepsUpdate="stepsUpdate" ref="assessmentRef"
          v-if="BtnIndex === 2"></my-PG>

        <!-- 检测记录 -->
        <my-JC :patientData="actPatientData" @stepsUpdate="stepsUpdate" ref="testingRef" v-if="BtnIndex === 3"></my-JC>
        <!-- 透后小结 -->
        <my-XJ :patientData="actPatientData" @stepsUpdate="stepsUpdate" ref="estimateRef" v-if="BtnIndex === 4"></my-XJ>
        <!-- 耗材记录 -->
        <Consumables :patientData="actPatientData" @stepsUpdate="stepsUpdate" ref="consumablesRef"
          v-if="BtnIndex === 5"></Consumables>
        <!-- 并发症 -->
        <Complication :patientData="actPatientData" v-if="BtnIndex === 6" @stepsUpdate="stepsUpdate" ref="mychild">
        </Complication>

        <!-- 基本信息 -->
        <div v-show="BtnIndex === 0" class="Dialysis">
          <!-- 头部 -->
          <div class="jniop"></div>
          <div class="juliss">基本信息</div>

          <el-form ref="ruleFormRef" :model="create" :rules="rules" :inline="true" class="demo-ruleForm">
            <el-form-item label="床位号：">
              <!-- <div style="display: flex"> -->
              <el-input v-model="create.bed_id" disabled />
              <!-- <div class="circie" @click="bed">
                  <el-icon>
                    <circle-plus />
                  </el-icon>
                </div> -->
              <!-- </div> -->
            </el-form-item>
            <el-form-item label="透析次数：" prop="dialysis_count">
              <el-input v-model="create.dialysis_count" clearable placeholder="请填写" />
            </el-form-item>

            <el-form-item label="医保类型：">
              <el-select clearable v-model="create.dict_payment_type" class="m-2" placeholder="请选择">
                <el-option v-for="item in dictList[103000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="护理等级：">
              <el-select clearable v-model="create.dict_huli_level" class="m-2" placeholder="请选择">
                <el-option v-for="item in dictList[119000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上次透析消毒：">
              <el-select clearable v-model="create.last_disinfection_flag" class="m-2" placeholder="请选择">
                <el-option v-for="item in lasttions" :key="item.index" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消毒液残留：">
              <el-select clearable v-model="create.last_disinfectant_residue_flag" class="m-2" placeholder="请选择">
                <el-option v-for="item in lasttions" :key="item.index" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="住院患者：">
              <div style="display: flex">
                <el-select clearable v-model="create.hospital_flag" class="m-2" placeholder="请选择">
                  <el-option v-for="item in lasttions" :key="item.index" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
                <div class="circie">
                  <el-icon>
                    <tools />
                  </el-icon>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="门诊号：" v-if="create.hospital_flag==0">
              <el-input clearable v-model="create.outpatient_no" placeholder="请填写" />
            </el-form-item>
            <el-form-item label="住院号：" v-if="create.hospital_flag==1">
              <el-input clearable v-model="create.hospital_no" placeholder="请填写" />
            </el-form-item>
            <el-form-item label="穿刺信息：">
              <div style="display: flex">
                <el-input clearable v-model="create.chuanci_desc" placeholder="请填写" />
                <div class="circie" @click="desc">
                  <el-icon>
                    <circle-plus />
                  </el-icon>
                </div>
              </div>
            </el-form-item>
            <el-form-item label=" 过敏史：" style="width: 100%">
              <el-input clearable v-model="create.allergy_history" :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea" placeholder="请输入">
              </el-input>
            </el-form-item>
            <el-form-item label=" 诊断：" style="width: 100%">
              <el-input clearable v-model="create.diagnosis" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                placeholder="请输入">
              </el-input>
            </el-form-item>
            <el-form-item label="上机时间：">
              <el-date-picker v-model="create.sjz_time" disabled type="datetime" format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm" placeholder="请选择"></el-date-picker>
            </el-form-item>
            <el-form-item label="下机时间：">
              <el-date-picker v-model="create.xjz_time" disabled type="datetime" format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm" placeholder="请选择"></el-date-picker>
            </el-form-item>
          </el-form>
        </div>

        <!-- 透析病情 -->
        <div v-show="BtnIndex === 0" class="Dialysis">
          <!-- 头部 -->
          <div class="jniop"></div>
          <div class="juliss">透析病情</div>

          <el-form ref="ruleFormRef" :model="create" :rules="rules" :inline="true" class="demo-ruleForm">
            <el-form-item label="上次透后体重：">
              <div style="display: flex">
                <el-input disabled v-model="create.last_touxi_weight" placeholder="">
                  <template #append>kg</template>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="干体重：">
              <div style="display: flex">
                <el-input clearable v-model="create.dry_weight" @input="Throughweightafter" placeholder="请填写">
                  <template #append>kg</template>
                </el-input>
                <div class="circie">
                  <el-icon>
                    <tools />
                  </el-icon>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="透前体重：">
              <div style="display: flex">
                <el-input clearable @input="Throughweightafter" v-model="create.befor_weight" placeholder="请填写">
                  <template #append>kg</template>
                </el-input>
                <div class="circie">
                  <el-icon>
                    <tools />
                  </el-icon>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="称重方式：">
              <el-select clearable v-model="create.befor_weight_way" class="m-2" placeholder="请选择">
                <el-option v-for="item in dictList[178000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="体重增长：">
              <div style="display: flex">
                <el-input v-model="create.befor_raise_weight" placeholder="请填写" disabled>
                  <template #append>kg</template>
                </el-input>
                <el-input style="border-left: none" v-model="create.befor_raise_weight_rate" placeholder="请填写" disabled>
                  <template #append>%</template>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="收压缩：">
              <div style="display: flex">
                <el-input clearable v-model="create.befor_systolic_pressure" placeholder="请填写">
                  <template #append>mmHg</template>
                </el-input>
                <div class="circie">
                  <el-icon>
                    <histogram />
                  </el-icon>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="舒张压：">
              <div style="display: flex">
                <el-input clearable v-model="create.befor_diastolic_pressure" placeholder="请填写">
                  <template #append>mmHg</template>
                </el-input>
                <div class="circie">
                  <el-icon>
                    <histogram />
                  </el-icon>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="脉搏：">
              <div style="display: flex">
                <el-input clearable v-model="create.befor_pulse" placeholder="请填写">
                  <template #append>次/分</template>
                </el-input>
                <div class="circie">
                  <el-icon>
                    <histogram />
                  </el-icon>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="测量部位：">
              <el-select clearable v-model="create.befor_pressure_way" class="m-2" placeholder="请选择">
                <el-option v-for="item in dictList[179000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="体温：">
              <el-input clearable v-model="create.befor_temperature" placeholder="请填写">
                <template #append>°C</template>
              </el-input>
            </el-form-item>
            <el-form-item label="24小时尿量：">
              <el-input clearable v-model="create.befor_urine24" placeholder="请填写">
                <template #append>ml</template>
              </el-input>
            </el-form-item>
            <el-form-item label="呼吸：">
              <el-input clearable v-model="create.befor_breath" placeholder="请填写">
                <template #append>次/分</template>
              </el-input>
            </el-form-item>
            <el-form-item label=" 病情：" style="width: 100%">
              <div style="display: flex">
                <el-input clearable v-model="create.condition" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                  placeholder="请输入">
                </el-input>
                <div class="divclis" @click="Usetemplate">使用模板添加</div>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <!-- 透析医嘱 -->
        <div v-show="BtnIndex === 0" style="
            display: flex;
            box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.12);
            flex-wrap: wrap;
            align-content: flex-start;
            margin-bottom: 25px;
          ">
          <!-- 头部 -->
          <div class="jniop"></div>
          <div class="juliss">透析医嘱</div>
          <el-form ref="ruleFormRef" :model="create" :rules="rules" :inline="true" class="demo-ruleForm">
            <el-form-item label="透析方式：" prop="dict_dialysis_type">
              <el-select clearable v-model="create.dict_dialysis_type" class="m-2" placeholder="请选择">
                <el-option @click="dialysis_way(item)" v-for="item in dictList[100000000]" :key="item.id"
                  :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="血管通路：">
              <el-select clearable v-model="create.dict_tonglu" class="m-2" placeholder="请选择">
                <el-option v-for="item in dictList[110000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预设时长：">
              <div style="display: flex">
                <input clearable class="inputlis" v-model="create.preset_dialysis_hour" />
                <div class="KGclis">时</div>
                <input clearable class="BFbi" v-model="create.preset_dialysis_min" />
                <div class="BFbiclis">分</div>
              </div>
            </el-form-item>
            <el-form-item label="实际时长：">
              <div style="display: flex">
                <input clearable class="inputlis" v-model="create.dialysis_hour" />
                <div class="KGclis">时</div>
                <input clearable class="BFbi" v-model="create.dialysis_min" />
                <div class="BFbiclis">分</div>
              </div>
            </el-form-item>
            <el-form-item label="血流速：">
              <div style="display: flex">
                <input clearable class="inputlis" v-model="create.xueliu_speed_min" />
                <div class="KGclis">-</div>
                <input clearable class="BFbi" v-model="create.xueliu_speed_max" />
                <div class="BFbiclis" style="width: 60px">ml/min</div>
              </div>
            </el-form-item>
            <el-form-item label="目标脱水：">
              <el-input clearable v-model="create.target_warter" placeholder="请填写">
                <template #append>
                  <div style="display: flex">
                    <div style="width: 20px">ml</div>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="存：">
              <el-input v-model="create.store_warter" placeholder="请填写">
                <template #append>kg</template>
              </el-input>
            </el-form-item> -->
            <el-form-item label="预脱水量：">
              <el-input clearable v-model="create.max_tuoshui_num" disabled>
                <template #append>ml</template>
              </el-input>
            </el-form-item>
            <el-form-item label="血透器：">
              <el-select clearable v-model="create.dict_touxiqi" class="m-2" placeholder="请选择">
                <el-option label="无" value="">
                </el-option>
                <el-option v-for="item in dictList[113000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>


            <el-form-item label="抗凝剂：">
              <div style="display: flex">
                <el-select clearable v-model="create.dict_kangning" class="m-2" placeholder="请选择">
                  <el-option label="无" value="">
                  </el-option>
                  <el-option v-for="item in dictList[116000000]" :key="item.id" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
                <div class="circie" @click="Kning">
                  <el-icon><circle-plus /></el-icon>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="动脉端：" v-if="create.dict_kangning=='116150000'">
              <el-input clearable v-model="create.dict_dong_mai_duan" placeholder="请填写">
                <template #append>mL/h</template>
              </el-input>
            </el-form-item>
            <el-form-item label="静脉端：" v-if="create.dict_kangning=='116150000'">
              <el-input clearable v-model="create.dict_jing_mai_duan" placeholder="请填写">
                <template #append>mL/h</template>
              </el-input>
            </el-form-item>
            <el-form-item label="用时：" v-if="create.dict_kangning=='116150000'">
              <el-input clearable v-model="create.use_time" placeholder="请填写">
                <template #append>h</template>
              </el-input>
            </el-form-item>
            <el-form-item class="select" label="首剂：">
              <el-input clearable v-model="create.kangning_shouji_num" placeholder="请填写" class="input-with-select">
                <template #append>
                  <el-select clearable v-model="create.dict_kangning_shouji_unit">
                    <el-option v-for="item in dictList[157000000]" :key="item.id" :label="item.name" :value="item.code">
                    </el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="追加/维持：">
              <el-select clearable v-model="create.dict_kangning_add" class="m-2">
                <el-option label="无" value="">
                </el-option>
                <el-option v-for="item in dictList[116000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>

            <div class="closhand" v-if="Kningvais">
              <div class="Close" @click="Kning">
                <el-icon>
                  <close />
                </el-icon>
              </div>
              <div class="clostext">第二针抗凝剂：</div>
              <div>
                <el-select clearable v-model="create.dict_kangning_part_1_name" class="m-2" placeholder="请选择">
                  <el-option v-for="item in dictList[116000000]" :key="item.id" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </div>

              <div style="width: 8%; margin: 0 10px 0 40px">
                <el-select clearable v-model="create.dict_kangning_operation_1_name" class="m-2" placeholder="请选择">
                  <el-option v-for="item in dictList[316000000]" :key="item.id" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </div>

              <div style="width: 20%">
                <div style="margin: 0 10px; display: flex; width: 264px">
                  <input clearable class="inputlis" style="width: 200px" v-model="create.kangning_num_part_1" />
                  <div style="width: 120px">
                    <el-select clearable v-model="create.kangning_unit_part_1" class="m-22">
                      <el-option v-for="item in dictList[157000000]" :key="item.id" :label="item.name"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <div style="width: 8%; margin: 0 10px 0 40px">
                <el-select clearable v-model="create.dict_kangning_operation_2_name" class="m-2" placeholder="请选择">
                  <el-option v-for="item in dictList[316000000]" :key="item.id" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </div>

              <div style="width: 20%">
                <div style="margin: 0 10px; display: flex; width: 264px">
                  <input clearable class="inputlis" style="width: 200px" v-model="create.kangning_num_part_2" />
                  <div style="width: 120px">
                    <el-select clearable v-model="create.kangning_unit_part_2" class="m-22">
                      <el-option v-for="item in dictList[157000000]" :key="item.id" :label="item.name"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <div style="display: flex; width: 35%; margin: 10px 10px 10px 6px">
                <div class="bodytxet">维持时间：</div>
                <div style="
                    margin: 0 10px;
                    display: flex;
                    width: 279px;
                    height: 32px;
                  ">
                  <input clearable class="inputlis" v-model="create.kangning_add_time_2_hour" />
                  <div class="KGclis">时</div>
                  <input clearable class="BFbi" v-model="create.kangning_add_time_2_min" />
                  <div class="BFbiclis">分</div>
                </div>
              </div>
            </div>
            <el-form-item class="select" label="追加/维持量：">
              <el-input clearable v-model="create.kangning_add_num" placeholder="请填写" class="input-with-select">
                <template #append>
                  <el-select clearable v-model="create.dict_kangning_add_num_unit">
                    <el-option v-for="item in dictList[157000000]" :key="item.id" :label="item.name" :value="item.code">
                    </el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="维持时间：">
              <div style="display: flex">
                <input clearable class="inputlis" v-model="create.kangning_add_time_hour" />
                <div class="KGclis">时</div>
                <input clearable class="BFbi" v-model="create.kangning_add_time_min" />
                <div class="BFbiclis">分</div>
              </div>
            </el-form-item>
            <el-form-item label="回血量：">
              <el-input clearable v-model="create.huixue_num" placeholder="请填写">
                <template #append>ml</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透中摄入：">
              <el-input clearable v-model="create.intake" placeholder="请填写">
                <template #append>ml</template>
              </el-input>
            </el-form-item>

            <el-form-item label="灌流器：">
              <el-select clearable v-model="create.dict_guanliuqi" class="m-2" placeholder="请选择" :clearable="true">
                <el-option label="无" value="">
                </el-option>
                <el-option v-for="item in dictList[115000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="血滤器：">
              <el-select clearable v-model="create.dict_xuelvqi" class="m-2" placeholder="请选择">
                <el-option label="无" value="">
                </el-option>
                <el-option v-for="item in dictList[114000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="回血泵速：">
              <el-input clearable v-model="create.huixue_beng_speed" placeholder="请填写">
                <template #append>ml/min</template>
              </el-input>
            </el-form-item>
            <el-form-item label="置换方式：">
              <el-select :disabled="disabled" v-model="create.dict_zhihuan_type" class="m-2">
                <el-option label="无" value="">
                </el-option>
                <el-option v-for="item in dictList[118000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="置液总量：">
              <el-input clearable v-model="create.zhihuan_num" :disabled="disabled">
                <template #append>L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="实际置液：">
              <el-input :disabled="disabled" v-model="create.actual_zhihuanye_total">
                <template #append>L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="置液流速：">
              <div style="display: flex">
                <input class="inputlis" :disabled="disabled" v-model="create.zhihuan_speed_min" />
                <div class="KGcliss">-</div>
                <input class="BFbi" style="width: 80px" v-model="create.zhihuan_speed_max" :disabled="disabled" />
                <div class="BFbiclis" style="width: 60px">L/h</div>
              </div>
            </el-form-item>
            <el-form-item label="超滤方式：">
              <el-select clearable v-model="create.dict_chaolv_mode" class="m-2" placeholder="请选择">
                <el-option v-for="item in dictList[339400000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="透析液温度：">
              <el-input clearable v-model="create.touxiye_temp" placeholder="请填写">
                <template #append>°C</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液流速：">
              <el-input clearable v-model="create.touxiye_speed" placeholder="请填写">
                <template #append>mL/min</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液k：">
              <el-input clearable v-model="create.k" placeholder="请填写">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液Ca：">
              <el-input clearable v-model="create.ca" placeholder="请填写">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液Na：">
              <el-input clearable v-model="create.na" placeholder="请填写">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液HCo3：">
              <el-input clearable v-model="create.hco3" placeholder="请填写">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="备注：" style="width: 100%">
              <el-input clearable v-model="create.remark" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                placeholder="请输入">
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <!-- 底部按钮 -->
        <div class="fiall" v-if="BtnIndex === 0">
          <div class="warings" @click="dialysisPlan">
            <div class="waringstext">保存到方案</div>
          </div>
          <div class="waringss" @click="conserve">
            <div class="waringstext" style="margin: 30px auto">保存</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 签到模板 -->
    <div>
      <BaseDialog :title="'手动签到'" :isshow="bodyvlus" @handleShow="
          (val) => {
            bodyvlus = val
          }
        " width="45%" height="auto">
        <el-form ref="ruleFormRef" :model="formInline" :rules="rules" :inline="true" style="margin-top: 20px"
          class="demo-ruleForm">
          <el-form-item label="时间：">
            <el-date-picker v-model="formInline.time" disabled type="date" value-format="YYYY-MM-DD"
              placeholder="请选择时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="选择组:">
            <el-select v-model="formInline.Selectgroup" class="m-2" placeholder="请选择">
              <el-option v-for="item in group" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班次:">
            <el-input class="inputel" v-model="formInline.user" min="2000" disabled />
          </el-form-item>
        </el-form>

        <!-- 分割线 -->
        <el-divider></el-divider>
        <!-- 按钮区域 -->
        <div style="display: flex; justify-content: space-between">
          <div></div>
          <div style="display: flex; justify-content: space-between; width: 20%">
            <button class="QXbtn" style="margin-right: 8px" @click="QXclick">
              取消
            </button>
            <button class="QEbtn" @click="QEclick">确定</button>
          </div>
        </div>
      </BaseDialog>
    </div>

    <!-- 使用模板添加 -->
    <div>
      <BaseDialog :title="'模板列表'" :isshow="tmbleval" @handleShow="
          (val) => {
            tmbleval = val
          }
        " width="40%" height="auto">
        <!-- 头部 -->
        <div class="tmb_hande">
          <el-button type="primary" @click="tmb_handebtn">
            <div class="el-iconright">
              <el-icon>
                <plus />
              </el-icon>
            </div>
            新增模板
          </el-button>
        </div>

        <div>
          <el-table class="sss" :data="tmplist" stripe height="220px" style="min-width: 100%" @row-click="tmp_tabclick">
            <el-table-column prop="tmpl_name" label="模板名称" />
            <el-table-column prop="updated_time" label="创建日期" />
            <el-table-column fixed="right" label="操作" width="180">
              <template #default="scope">
                <el-button type="text" size="small" :loading="saveLoad" @click="tmb_btn(scope.row)">
                  <i class="txIons use"></i>
                </el-button>

                <el-button type="text" size="small" :loading="saveLoad" @click="tmb_alter(scope.row)">
                  <i class="txIons modify"></i>
                </el-button>
                <el-popconfirm title="是否确认删除？" @confirm="tmb_dele(scope.row.id)">
                  <template #reference>
                    <el-button type="text" size="small" :loading="saveLoad" @click="tmb_btn(scope.row)">
                      <i class="txIons del"></i>
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>

          <!-- 模板内容区 -->
          <div class="tmb_body" v-if="this.addtmb">{{ tmpl_content }}</div>

          <!-- 添加模板 -->
          <div style="margin-top: 16px" v-else>
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules">
              <el-form-item label="模板名称" prop="tmpl_name">
                <el-input v-model="ruleForm.tmpl_name"></el-input>
              </el-form-item>
              <el-form-item label="模板内容" prop="tmpl_content">
                <el-input v-model="ruleForm.tmpl_content" maxlength="225" show-word-limit type="textarea"></el-input>
              </el-form-item>
              <!-- 分割线 -->
              <el-divider></el-divider>
              <el-form-item>
                <!-- 按钮区域 -->
                <div style="display: flex; justify-content: flex-end; width: 100%">
                  <button class="QXbtn" style="margin: 0 10px" @click="tmb_QX">
                    取消
                  </button>
                  <button class="QEbtn" @click="submitForm">确定</button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </BaseDialog>
    </div>

    <!-- 选择床位号 -->
    <div>
      <BaseDialog :title="'床位号选择'" :isshow="bedval" @handleShow="
          (val) => {
            bedval = val
          }
        " width="44%" height="auto">
        <!-- 头部 -->
        <div style="margin: 10px 0">
          <el-form :inline="true" :model="formInline" class="bidmode">
            <el-form-item label="透析时间:" style="width: 220px">
              <el-date-picker v-model="formInline.time" type="date" value-format="YYYY-MM-DD" placeholder="请选择时间"
                disabled></el-date-picker>
            </el-form-item>
            <el-form-item label="班次:">
              <div class="bidcliss">
                {{ formInline.user }}
              </div>
            </el-form-item>
            <el-form-item label="院区:">
              <div class="bidcliss">
                {{ formInline.Yval }}
              </div>
            </el-form-item>
            <el-form-item label="分组:">
              <div :class="[receptionIndex === index ? 'bidcliss' : 'bidclis']" v-for="(item, index) in reception"
                :key="item.id" @click="receptions(index, item.id)">
                {{ item.name }}
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 床位号内容 -->
        <div class="recepbody">
          <div v-show="bed_no.length != 0" :class="[bidIndex === indexs ? 'body_bids' : 'body_bid']"
            v-for="(items, indexs) in bed_no" :key="indexs" @click="bidno(indexs, items)">
            床位号：{{ items.bed_no }}
          </div>
          <div v-show="bed_no.length == 0">暂无数据！</div>
        </div>
        <!-- 分割线 -->
        <el-divider />
        <!-- 底部按钮 -->
        <div style="display: flex; justify-content: space-between">
          <div></div>
          <div class="display_flex">
            <el-button @click="bedval = false">取消</el-button>
            <el-button type="primary" @click="receconfirm">确定</el-button>
          </div>
        </div>
      </BaseDialog>
    </div>

    <!-- 穿刺内容选择 -->
    <div>
      <BaseDialog :title="'穿刺信息'" :isshow="puncture" @handleShow="
          (val) => {
            puncture = val
          }
        " width="50%" height="auto">
        <!-- 头部 -->
        <div class="Thepiercing">
          <div style="display: flex">
            <div>当前穿刺信息为：</div>
            <div>{{ create.chuanci_desc }}</div>
          </div>
          <div>
            <el-radio-group v-model="create.is_fistula" @change="is_fistulaChange">
              <el-radio :label="134000000">内瘘</el-radio>
              <el-radio :label="350000000">非内瘘</el-radio>
            </el-radio-group>
            <!-- <el-radio v-model="create.is_fistula" label="1" size="large">内瘘</el-radio>
            <el-radio v-model="create.is_fistula" label="2" size="large">非内瘘</el-radio> -->
          </div>
        </div>
        <!-- 中间内容区 -->
        <div>
          <el-form ref="ruleFormRef" :model="formInline" :rules="rules" :inline="true" class="demoform"
            v-if="create.is_fistula === 350000000">
            <el-form-item label="穿刺方式：">
              <el-select v-model="create.dict_chuanci_type" class="m-2" placeholder="请选择"
                @change="nameSet('dict_chuanci_type_name',dictList[350000000],create.dict_chuanci_type)">
                <el-option v-for="item in dictList[350000000]" :key="item.id" :label="item.name" :value="item.code" />
              </el-select>
            </el-form-item>
          </el-form>
          <el-form ref="ruleFormRef" :model="formInline" :rules="rules" :inline="true" class="demo-form"
            v-if="create.is_fistula === 134000000">
            <el-form-item label="A端穿刺方法：">
              <el-select v-model="create.dict_a_chuanci_way" class="m-2" placeholder="请选择"
                @change="nameSet('dict_a_chuanci_way_name',dictList[134000000],create.dict_a_chuanci_way)">
                <el-option v-for="item in dictList[134000000]" :key="item.id" :label="item.name" :value="item.code" />
              </el-select>
            </el-form-item>
            <el-form-item label="V端穿刺方法：">
              <el-select v-model="create.dict_v_chuanci_way" class="m-2" placeholder="请选择"
                @change="nameSet('dict_v_chuanci_way_name',dictList[134000000],create.dict_v_chuanci_way)">
                <el-option v-for="item in dictList[134000000]" :key="item.id" :label="item.name" :value="item.code" />
              </el-select>
            </el-form-item>

            <el-form-item label="A端穿刺针型号:">
              <el-select v-model="create.dict_a_chuanci_zhen_type" class="m-2" placeholder="请选择"
                @change="nameSet('dict_a_chuanci_zhen_type_name',dictList[136000000],create.dict_a_chuanci_zhen_type)">
                <el-option v-for="item in dictList[136000000]" :key="item.id" :label="item.name" :value="item.code" />
              </el-select>
            </el-form-item>
            <el-form-item label="V端穿刺针型号:">
              <el-select v-model="create.dict_v_chuanci_zhen_type" class="m-2" placeholder="请选择"
                @change="nameSet('dict_v_chuanci_zhen_type_name',dictList[136000000],create.dict_v_chuanci_zhen_type)">
                <el-option v-for="item in dictList[136000000]" :key="item.id" :label="item.name" :value="item.code" />
              </el-select>
            </el-form-item>
            <el-form-item label="A端穿刺针类型:">
              <el-select v-model="create.dict_a_chuanci_zhen" class="m-2" placeholder="请选择">
                <el-option v-for="item in dictList[135000000]" :key="item.id" :label="item.name" :value="item.code"
                  @change="nameSet('dict_a_chuanci_zhen_name',dictList[135000000],create.dict_a_chuanci_zhen)">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="V端穿刺针类型:">
              <el-select v-model="create.dict_v_chuanci_zhen" class="m-2" placeholder="请选择"
                @change="nameSet('dict_v_chuanci_zhen_name',dictList[135000000],create.dict_v_chuanci_zhen)">
                <el-option v-for="item in dictList[135000000]" :key="item.id" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>

          </el-form>
        </div>
        <el-divider />
        <div style="text-align: right">
          <el-button @click="puncture = false">取消</el-button>
          <el-button type="primary" @click="Confirmpuncture">确定</el-button>
        </div>
      </BaseDialog>


    </div>
    <div>
      <BaseDialog title="日历提醒" :isshow="modRemind" @handleShow="
        (val) => {
          modRemind = val
        }
      " width="650px" height="auto">
        <div class="schemeBox" style="margin-top: 10px;">
          <div class="userItem" style="margin-top: 10px;">
            <el-alert :title="ite.notice_date+ '&#x3000;' + ite.notice_content" type="success" :closable="false"
              v-for="ite in ruleForm1 " :key="index">
            </el-alert>
          </div>
        </div>
      </BaseDialog>
    </div>
  </div>
</template>
<script>
  import {
    Search,
    CirclePlus,
    Tools,
    Histogram,
    Close,
    Plus,
  } from '@element-plus/icons-vue'
  import myYZ from './advice/index.vue'
  import myPG from '../TXjilu/THPG.vue'
  import myJC from '../TXjilu/testing/index.vue'
  import myXJ from './estimate/THXJ.vue'
  import Complication from '../TXjilu/BFZ.vue'
  import Consumables from './consumables/index.vue'
  import service from '@/utils/request'
  import BaseDialog from '@/components/Dialog/index.vue'
  import TxSteps from '@/components/Steps/TxSteps.vue'
  import { gitDictList, TimeYear, getDiffDay, ruleTimeYear } from '@/utils/tool'
  import { ElMessageBox, ElMessage } from 'element-plus'
  // import { isTemplate } from 'element-plus/lib/utils'
  import print from 'vue3-print-nb'
  import { useRoute } from 'vue-router'
  export default {
    components: {
      Search,
      CirclePlus,
      Tools,
      Plus,
      Histogram,
      myYZ,
      myPG,
      myJC,
      Close,
      myXJ,
      BaseDialog,
      Consumables,
      Complication,
      TxSteps,
    },
    directives: {
      print,
    },
    data() {
      return {
        stepsData: {},
        listIndex: 0,
        sutas: true,
        preconval: {},
        value1: '',
        input3: '',
        lisding: false,
        Kningvais: false,
        tmbleval: false,
        puncture: false,
        bodyvlus: false,
        bedval: false,
        addtmb: true,
        tmpl_content: '',
        formInline: {
          user: '',
          time: TimeYear(new Date()),
          beforeOpenCallback: () => {
            return false
          },
        },
        printLoad: false,
        print: {
          id: 'printArea',
          popTitle: '打印小条',
        },
        printData: {
          base: {},
          tonglu: {},
          chuanci: {},
        },
        rules: {
          tmpl_name: [
            { required: true, message: '请输入模板名称', trigger: 'blur' },
            { min: 1, max: 5, message: '长度在 1到 5 个字符', trigger: 'blur' },
          ],
          tmpl_content: [
            { required: true, message: '请填写模板内容', trigger: 'blur' },
          ],
          dialysis_count: [
            { required: true, message: '请填写', trigger: 'blur' },
          ],
          dict_dialysis_type: [
            { required: true, message: '请填写', trigger: 'blur' },
          ],

        },
        modRemind: false,
        ruleForm1: [],
        lasttions: [
          { name: '是', code: 1 },
          { name: '否', code: 0 },
        ],
        list: [],
        tmplist: [],
        btnlist: [
          { name: '透析信息' },
          { name: '执行医嘱' },
          { name: '透前评估' },
          { name: '监测记录' },
          { name: '透后小结' },
          { name: '耗材记录' },
          { name: '并发症' },
        ],
        options: [
          {
            value: '上午',
            label: '上午',
          },
          {
            value: '下午',
            label: '下午',
          },
          {
            value: '晚上',
            label: '晚上',
          },
        ],
        BtnIndex: 0,
        value: '',
        Ylist: [],
        is_sign: -1,
        hospid: '',
        create: {
          patient_id: '',
          bed_id: '',
          dialysis_count: '',
          dict_payment_type: '',
          dict_huli_level: '',
          last_disinfection_flag: '',
          last_disinfectant_residue_flag: '',
          hospital_flag: '',
          hospital_no: '',
          outpatient_no: '',
          chuanci_desc: '',
          allergy_history: '',
          diagnosis: '',
          dry_weight: '',
          befor_weight: '',
          add_weight: '',
          befor_weight_way: '',
          befor_clean_weight: '',
          befor_raise_weight: '',
          befor_raise_weight_rate: '',
          befor_systolic_pressure: '',
          befor_diastolic_pressure: '',
          befor_pulse: '',
          befor_pressure_way: '',
          befor_temperature: '',
          befor_urine24: '',
          befor_breath: '',
          condition: '',
          dict_dialysis_type: '',
          dict_tonglu: '',
          dict_touxiqi: '',
          k: '',
          ca: '',
          na: '',
          hco3: '',
          touxiye_temp: '',
          touxiye_speed: '',
          dict_langning: '',
          kangning_shouji_num: '',
          kangning_shouji_unit: '',
          dict_kangning_add: '',
          dict_jing_mai_duan: 0,
          dict_dong_mai_duan: 0,
          kangning_add_num: '',
          dict_kangning_add_num_unit: '',
          kangning_add_time_hour: '',
          kangning_add_time_min: '',
          dict_kangning_part_1_name: '',
          dict_kangning_operation_1_name: '',
          kangning_num_part_1: '',
          kangning_unit_part_1: "157160000",
          dict_kangning_operation_2_name: '',
          kangning_num_part_2: '',
          kangning_unit_part_2: "157160000",
          kangning_add_time_2_hour: '',
          kangning_add_time_2_min: '',
          huixue_num: '',
          intake: '',
          target_warter: '',
          store_warter: '',
          max_tuoshui_num: '',
          dict_guanliuqi: '',
          dict_xuelvqi: '',
          preset_dialysis_hour: '',
          preset_dialysis_min: '',
          dialysis_hour: '',
          dialysis_min: '',
          xueliu_speed_min: '',
          xueliu_speed_max: '',
          huixue_beng_speed: '',
          dict_zhihuan_type: '',
          zhihuan_num: '',
          actual_zhihuanye_total: '',
          zhihuanye_speed_min: '',
          zhihuanye_speed_max: '',
          dict_chaolv_mode: '',
          dict_flush_mode: '',
          remark: '',
          cgz_id: '',
          ccz_id: '',
          jxz_id: '',
          sjz_id: '',
          schedule_bed_id: null,
          dict_code: '',
          date: '',
          is_fistula: 134000000
        },
        group: [],
        tmb_id: null,
        userInfo: [],
        dictList: {},
        ruleForm: {},
        tmb_Index: 0,
        record_id: null,
        imgval:
          'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',

        actPatientData: {},
        signin: false,
        dict_chuanci_type: '',
        Adistance: '',
        Vdistance: '',
        AVdistance: '',
        disabled: true,
        reception: [],
        receptionIndex: 0,
        bed_no: [],
        bidIndex: null,
        sid: null,
        defaultBanci: '',
        currentStep: -1,
        statusCount: {
          all: '0',
          sign: '0',
          nosign: '0',
        },
        timer: null,
        stepsStatusData: [
          {
            name: '签到',
            active: false,
          },
          {
            name: '体重',
            active: false,
          },
          {
            name: '血压',
            active: false,
          },
          {
            name: '接诊',
            active: false,
          },
          {
            name: '上机',
            active: false,
          },
          {
            name: '执行',
            active: false,
          },
          {
            name: '核对',
            active: false,
          },
          {
            name: '监测',
            active: false,
          },
          {
            name: '下机',
            active: false,
          },
          {
            name: '消毒',
            active: false,
          },
        ],
        isSave: false
      }
    },
    async created() {
      console.log(this.$route.query)
      if (this.$route.query.toTX == '1') {
        this.formInline = this.$route.query
        await this.getDefaultBanci()
        // 获取院区列表
        this.Yanqu()
        // 获取字典
        this.getDict()
        this.getUserList()
      } else {
        await this.getDefaultBanci()
        // 获取院区列表
        this.Yanqu()
        // 获取字典
        this.getDict()
        this.getUserList()
        this.timer = setInterval(this.timeOUt, 1000)
      }

      // 获取缓存数据
      // const user = localStorage.getItem('ms_userData')
      // this.userInfo.push(JSON.parse(user))
    },
    unmounted() {
      clearTimeout(this.timer)
    },
    methods: {
      async getUserList() {
        let res = await service.post('/api/user/get_all_nurse')
        if (res.code === 0) {
          this.userInfo = res.data.list
        }
      },
      async getDefaultBanci() {
        let res = await service.post('/api/common/get_default_banci')
        if (res.code === 0) {
          this.defaultBanci = res.data.nm_name
          this.formInline.user = this.defaultBanci
        }
      },
      async printInit() {
        if (!this.actPatientData.record_id) {
          return
        }
        this.printLoad = true
        const res = await service.post('/api/dialysis_record/getStampValue', {
          record_id: this.actPatientData.record_id,
        })
        this.printLoad = false
        if (res.code === 0) {
          this.printData = res.data
          this.printData.time = TimeYear(new Date())
        }
      },
      // 签到切换
      Qiando(e) {
        // this.sutas = !this.sutas
        // if (!this.sutas) {
        //   this.is_sign = 0
        // } else {
        //   this.is_sign = 1
        // }
        this.is_sign = e
        this.create = {}
        console.log(this.create, '签到')
        this.user()
      },
      async stepsUpdate() {
        this.printInit()
        let data = {
          patient_id: this.actPatientData.patient_id,
          hospital_area_id: this.hospid,
          code: this.formInline.user,
          date: this.formInline.time,
          record_id: this.actPatientData.record_id
        }
        const res = await service.post(
          '/api/dialysis_record/get_dialysis_status',
          data
        )
        if (res.code === 0) {
          console.log(' this.stepsData===', this.stepsData)
          this.stepsData = res.data
          if (res.data.is_sign) {
            this.stepsStatusData[0].active = true
          } else {
            this.stepsStatusData[0].active = false
          }
          if (res.data.is_weight) {
            this.stepsStatusData[1].active = true
          } else {
            this.stepsStatusData[1].active = false
          }
          if (res.data.is_pressure) {
            this.stepsStatusData[2].active = true
          } else {
            this.stepsStatusData[2].active = false
          }
          if (res.data.is_diagnose) {
            this.stepsStatusData[3].active = true
          } else {
            this.stepsStatusData[3].active = false
          }
          if (res.data.is_on) {
            this.stepsStatusData[4].active = true
          } else {
            this.stepsStatusData[4].active = false
          }
          if (res.data.is_do) {
            this.stepsStatusData[5].active = true
          } else {
            this.stepsStatusData[5].active = false
          }
          if (res.data.is_check) {
            this.stepsStatusData[6].active = true
          } else {
            this.stepsStatusData[6].active = false
          }
          if (res.data.is_monitor) {
            this.stepsStatusData[7].active = true
          } else {
            this.stepsStatusData[7].active = false
          }
          if (res.data.is_off) {
            this.stepsStatusData[8].active = true
          } else {
            this.stepsStatusData[8].active = false
          }
          if (res.data.is_degassing) {
            this.stepsStatusData[9].active = true
          } else {
            this.stepsStatusData[9].active = false
          }
        }
        if (this.BtnIndex == 3) {
          this.user1()
        }
      },
      // 获取用户列表
      async user1() {
        let data = {
          nm_name: this.formInline.user,
          hospital_area_id: this.hospid,
          is_sign: this.is_sign,
          date: this.formInline.time,
          patient_name: this.formInline.name,
        }
        const val = await service.post('/api/dialysis_record/patient', data)
        if (val.code === 0) {
          this.list = val.data
          this.list.map(v => {
            let a = 0
            if (v.notice_list.length > 0) {
              if (ruleTimeYear(new Date()) > v.notice_list[0].notice_date) {
                a = getDiffDay(ruleTimeYear(new Date()), v.notice_list[0].notice_date)
                console.log(a, ruleTimeYear(new Date()))
                if (a <= 2) {
                  v.colorSet = 'red'
                } else if (a > 2) {
                  v.colorSet = 'green'
                }
              } else {
                a = getDiffDay(v.notice_list[0].notice_date, ruleTimeYear(new Date()))
                console.log(a, ruleTimeYear(new Date()), 222)
                if (a <= 1) {
                  v.colorSet = 'red'
                } else {
                  v.colorSet = 'aaa'
                }
              }
            } else {
              v.colorSet = 'aaa'
            }


          })
          if (this.list.length > 0) {
            if (this.listIndex <= this.list.length - 1) {
              this.actPatientData = val.data[this.listIndex]
            } else {
              this.actPatientData = val.data[0]
            }
            this.record_id = this.actPatientData.record_id
            this.group = [this.actPatientData.group]
            this.create.patient_id = this.actPatientData.patient_id
            this.create.schedule_bed_id = this.actPatientData.id
            this.create.bed_id = this.actPatientData.bed_num
            this.create.dict_tonglu = this.actPatientData.dict_tonglu
            // if(this.create.kangning_unit_part_1==''||this.create.kangning_unit_part_1==null){
            //   this.create.kangning_unit_part_1='157160000'
            // }
            // if(this.create.kangning_unit_part_2==''||this.create.kangning_unit_part_2==null){
            //   this.create.kangning_unit_part_2='157160000'
            // }
            // 调取信息
            this.initwar(this.actPatientData)
          } else {
            this.actPatientData = {}
          }
          console.log('this.list==', this.list)
          this.list.forEach((item) => {
            // item.countdown = 5000
            // let h = Math.floor(item.countdown / 3600)
            // let m = Math.ceil(Math.floor(item.countdown % 3600) / 60)
            // let s = item.countdown % 60
            // item.countdownZw = `${h}:${m}:${s}`
            let h = Math.floor(item.countdown / 3600)
            let m = Math.floor((item.countdown / 60) % 60)
            let s = item.countdown % 60
            let hour = h < 10 ? '0' + h : h
            let min = m < 10 ? '0' + m : m
            let sec = s < 10 ? '0' + s : s
            item.countdownZw = `${hour}:${min}:${sec}`
          })
          if (this.is_sign == -1) {
            let nosign = 0
            this.list.forEach((item) => {
              if (item.is_sign === 0) {
                nosign = nosign + 1
              }
            })
            this.statusCount = {
              all: this.list.length,
              sign: this.list.length - nosign,
              nosign: nosign,
            }
          }
        }
      },
      // 左侧列表点击事件
      async bodycl(index, item) {
        console.log('点击左侧', item, index)
        this.actPatientData = item
        this.stepsUpdate()
        this.listIndex = index
        this.group = [item.group]
        if (this.group.length > 0) {
          this.formInline.Selectgroup = this.group[0].id
        }
        this.create.schedule_bed_id = item.id
        this.create.patient_id = item.patient_id
        this.create.bed_id = item.bed_num
        this.record_id = item.record_id
        let data = {
          patient_id: this.create.patient_id,
          date: this.formInline.time,
          dict_code: this.formInline.user,
          record_id: this.record_id,
          bed_id: item.id
        }
        const val = await service.post('/api/dialysis_record/info', data)
        if (val.code === 0) {
          if (val.data.length != 0) {
            this.create = val.data
            console.log(this.create.id, '左侧点击赋值')
            this.create.bed_id = item.bed_num
            this.create.schedule_bed_id = item.id
            if (
              this.create.dict_dialysis_type_name == 'HF' ||
              this.create.dict_dialysis_type_name == 'HDF' ||
              this.create.dict_dialysis_type_name == 'HDF-FX600+HP' ||
              this.create.dict_dialysis_type_name == 'UF+HF' ||
              this.create.dict_dialysis_type_name == 'HP+HF'
            ) {
              this.disabled = false
            } else {
              this.disabled = true
            }
            this.weight(this.create.befor_weight)

            // 点击患者列表 更新并发症列表
            if (this.BtnIndex === 6) {
              this.$refs.mychild.monitor()
            }
          }
        }
      },
      toHZXQ(item) {
        this.$router.push({
          path: `/patients/patientDetails/${item.patient_id}`,
          query: {
            name: item.patient_name + '病历详情',
          }
        })
      },
      // 选择床位号确定
      receconfirm() {
        let val = JSON.parse(JSON.stringify(this.preconval))
        this.create.bed_id = val.bed_no
        this.bedval = false
      },
      // 打开签到弹窗
      Pclick(val) {
        this.group = [val.group]
        this.bodyvlus = true
      },
      // 取消按钮
      QXclick() {
        this.bodyvlus = false
      },
      //刷新
      Refresh() {
        // this.$router.go(0)
        let that = this
        ElMessageBox.confirm(
          '刷新当前患者数据会丢失当前未保存的数据，是否继续？',
          '操作提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(async () => {
            switch (that.BtnIndex) {
              case 0:
                that.user()
                break
              case 1:
                that.$refs.adviceRef.getDataList()
                break
              case 2:
                that.$refs.assessmentRef.getDataList()
                break
              case 3:
                that.$refs.testingRef.getDataList()
                break
              case 4:
                that.$refs.estimateRef.getDataList()
                break
              case 5:
                that.$refs.mychild.monitor()
                break
            }
          })
          .catch(() => { })
      },
      // 按钮选择
      butncls(val) {
        this.BtnIndex = val
        if (val == 0) {
          this.initwar(this.actPatientData)
        }
      },
      // 追加抗凝剂
      Kning() {
        this.Kningvais = !this.Kningvais
      },
      // 院区列表
      async Yanqu() {
        const val = await service.post('/api/hospital/list', { parent_id: 0 })
        if (val.code === 0) {
          this.hospi(val.data[0].id)
          this.Ylist = val.data
          this.formInline.Yval = val.data[0].name
          this.hospid = val.data[0].id
        }
        this.user()
      },
      // 获取用户列表
      async user() {
        let data = {
          nm_name: this.formInline.user,
          hospital_area_id: this.hospid,
          is_sign: this.is_sign,
          date: this.formInline.time,
          patient_name: this.formInline.name,
        }
        console.log('园区', data)
        const val = await service.post('/api/dialysis_record/patient', data)
        if (val.code === 0) {
          if (this.isSave == false) {
            this.listIndex = 0
          }
          // if(this.actPatientData.is_sign == 0){
          //   //未签
          //   this.listIndex = 0
          // }
          this.list = val.data
          this.list.map(v => {
            let a = 0
            if (v.notice_list.length > 0) {
              if (ruleTimeYear(new Date()) > v.notice_list[0].notice_date) {
                a = getDiffDay(ruleTimeYear(new Date()), v.notice_list[0].notice_date)
                console.log(a, ruleTimeYear(new Date()))
                if (a <= 2) {
                  v.colorSet = 'red'
                } else if (a > 2) {
                  v.colorSet = 'green'
                }
              } else {
                a = getDiffDay(v.notice_list[0].notice_date, ruleTimeYear(new Date()))
                console.log(a, ruleTimeYear(new Date()), 222)
                if (a <= 1) {
                  v.colorSet = 'red'
                } else {
                  v.colorSet = 'aaa'
                }
              }
            } else {
              v.colorSet = 'aaa'
            }
          })
          console.log('this.listIndex==', this.listIndex)
          if (this.list.length > 0) {
            if (this.listIndex <= this.list.length - 1) {
              this.actPatientData = val.data[this.listIndex]
            } else {
              this.actPatientData = val.data[0]
            }
            this.record_id = this.actPatientData.record_id
            this.group = [this.actPatientData.group]
            this.create.patient_id = this.actPatientData.patient_id
            this.create.schedule_bed_id = this.actPatientData.id
            this.create.bed_id = this.actPatientData.bed_num
            this.create.dict_tonglu = this.actPatientData.dict_tonglu
            // 调取信息
            this.initwar(this.actPatientData)
          } else {
            this.actPatientData = {}
          }
          console.log('this.list==', this.list)
          this.list.forEach((item) => {
            // item.countdown = 5000
            // let h = Math.floor(item.countdown / 3600)
            // let m = Math.ceil(Math.floor(item.countdown % 3600) / 60)
            // let s = item.countdown % 60
            // item.countdownZw = `${h}:${m}:${s}`
            let h = Math.floor(item.countdown / 3600)
            let m = Math.floor((item.countdown / 60) % 60)
            let s = item.countdown % 60
            let hour = h < 10 ? '0' + h : h
            let min = m < 10 ? '0' + m : m
            let sec = s < 10 ? '0' + s : s
            item.countdownZw = `${hour}:${min}:${sec}`
          })
          this.stepsUpdate()
          if (this.is_sign == -1) {
            let nosign = 0
            this.list.forEach((item) => {
              if (item.is_sign === 0) {
                nosign = nosign + 1
              }
            })
            this.statusCount = {
              all: this.list.length,
              sign: this.list.length - nosign,
              nosign: nosign,
            }
          }
        }
      },
      // 页面刚进来 默认加载第一个患者数据
      async initwar(id) {
        let data = {
          patient_id: id.patient_id,
          date: this.formInline.time,
          dict_code: this.formInline.user,
          record_id: id.record_id,
          bed_id: id.id
        }
        const val = await service.post('/api/dialysis_record/info', data)
        if (val.code === 0) {
          // if (!val.data.id) {
          //   //处理当有数据的时候覆盖问题
          //   this.getInitWay()
          // }
          if (val.data.length != 0) {
            this.create = val.data
            this.create.is_fistula = val.data.is_fistula
            console.log('第一个患者赋值', this.create.id)
            if (
              this.create.dict_dialysis_type_name == 'HF' ||
              this.create.dict_dialysis_type_name == 'HDF' ||
              this.create.dict_dialysis_type_name == 'HDF-FX600+HP' ||
              this.create.dict_dialysis_type_name == 'UF+HF' ||
              this.create.dict_dialysis_type_name == 'HP+HF'
            ) {
              this.disabled = false
            } else {
              this.disabled = true
            }
            this.weight(this.create.befor_weight)
          } else {
            this.actPatientData = {}
          }
        }
      },
      async getInitWay() {
        const res = await service.post('/api/dialysis_record/init_way', {
          patient_id: this.actPatientData.patient_id,
          dict_dialysis_type: this.actPatientData.dict_dialyse_way,
        })
        if (res.code === 0) {
          if (!Array.isArray(res.data)) {
            this.create = Object.assign(this.create, res.data)
            delete this.create.id
            console.log(this.create.id, '获取到透析方式赋值')
            this.create.bed_id = this.list[0].bed_num
            this.create.schedule_bed_id = this.actPatientData.id
            this.weight(this.create.befor_weight)
          }
        }
      },
      //删除签到
      async Delete() {
        const val = await service.post('/api/dialysis_record/delete_sign', {
          schedule_bed_id: this.create.schedule_bed_id,
        })
        if (val.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.statusCount = {
            all: this.statusCount.all,
            sign: this.statusCount.sign - 1,
            nosign: this.statusCount.nosign + 1,
          }
          this.user2()
        }
      },
      // 删除透析记录
      async deleiDialysis() {
        const val = await service.post('/api/dialysis_record/delete', {
          patient_id: this.create.patient_id,
          id: this.record_id,
        })
        if (val.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.user()
        }
      },
      // 院区选择
      async hospi(id) {
        const res = await service.post('/api/hospital/list', { parent_id: id })
        if (res.code === 0) {
          this.reception = res.data
          this.sid = res.data[0].hospital.id
          this.receptions(0, res.data[0].id)
        }
        this.hospid = id

        this.user()
      },
      // 查询
      search() {
        this.is_sign = -1
        this.create = {}
        console.log(this.create, '查询')
        this.user()
      },
      // 重置
      refresh() {
        this.$message({
          message: '重置成功',
          type: 'success',
        })
        this.formInline.user = this.defaultBanci
        this.formInline.time = TimeYear(new Date())
        this.formInline.Yval = this.Ylist[0].name
        this.hospid = this.Ylist[0].id
      },
      // 保存
      async conserve() {
        if (!this.create.dialysis_count) {
          return this.$message.error('透析次数必填')
        } else if (!this.create.dict_dialysis_type) {
          return this.$message.error('透析方式必填')
        } else {
          if (this.actPatientData.is_sign === 0) {
            ElMessageBox.confirm(
              '当前患者还未签到，请先签到后在进行保存',
              '操作提示',
              {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
              }
            )
              .then(async () => {
                this.signin = true
                // 先签到
                this.bodyvlus = true
              })
              .catch(() => { })
          } else {
            this.create.date = this.formInline.time
            this.create.dict_code = this.formInline.user
            this.create.hospital_area_id = this.hospid
            this.create.is_fistula = Number(this.create.is_fistula)
            if (this.create.hospital_flag == 0) {
              delete this.create.hospital_no
            } else {
              delete this.create.outpatient_no
            }

            // delete this.create.id
            delete this.create.is_on
            delete this.create.is_check
            delete this.create.is_off
            delete this.create.sjz_id_name
            delete this.create.sjz_id
            delete this.create.hdz_id_name
            delete this.create.hdz_id
            delete this.create.xjz_id_name
            delete this.create.xjz_id
            console.log(this.create)
            const res = await service.post(
              '/api/dialysis_record/save_record',
              this.create
            )
            if (res.code === 0) {
              this.isSave = true
              this.$message({
                message: '保存成功',
                type: 'success',
              })
              this.create = {}
              this.stepsUpdate()
              // this.listIndex = 0
              this.user()
            }
          }
        }
      },
      async dialysisPlan() {
        if (!this.create.dialysis_count) {
          return this.$message.error('透析次数必填')
        } else if (!this.create.dict_dialysis_type) {
          return this.$message.error('透析方式必填')
        } else {
          if (this.actPatientData.is_sign === 0) {
            ElMessageBox.confirm(
              '当前患者还未签到，请先签到后在进行保存',
              '操作提示',
              {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
              }
            )
              .then(async () => {
                this.signin = true
                // 先签到
                this.bodyvlus = true
              })
              .catch(() => { })
          } else {
            this.create.date = this.formInline.time
            this.create.dict_code = this.formInline.user
            this.create.hospital_area_id = this.hospid

            // if (this.create.is_fistula === '1') {
            //   this.create.is_fistula = '134000000'
            // } else {
            //   this.create.is_fistula = '350000000'
            // }

            delete this.create.id
            const res = await service.post(
              '/api/patient_dialysis/save_patient_dialysis_plan',
              this.create
            )
            if (res.code === 0) {
              this.$message({
                message: '保存成功',
                type: 'success',
              })
            }
          }
        }
      },

      // 弹窗确定
      async QEclick() {
        console.log('this.listIndex签到的索引==', this.listIndex)
        const val = await service.post('/api/dialysis_record/sign', {
          schedule_bed_id: this.create.schedule_bed_id,
        })
        if (val.code === 0) {
          this.$message({
            message: '签到成功',
            type: 'success',
          })
          this.stepsUpdate()
          this.actPatientData.is_sign = 1
          if (this.signin) {
            this.conserve()
            this.signin = false
          } else {
            this.user2()
          }
        }
        this.bodyvlus = false
      },
      // 获取用户列表
      async user2() {
        let data = {
          nm_name: this.formInline.user,
          hospital_area_id: this.hospid,
          is_sign: this.is_sign,
          date: this.formInline.time,
          patient_name: this.formInline.name,
        }
        console.log('园区', data)
        const val = await service.post('/api/dialysis_record/patient', data)
        if (val.code === 0) {
          // if(this.actPatientData.is_sign == 0){
          //   //未签
          //   this.listIndex = 0
          // }
          this.list = val.data
          this.list.map(v => {
            let a = 0
            if (v.notice_list.length > 0) {
              if (ruleTimeYear(new Date()) > v.notice_list[0].notice_date) {
                a = getDiffDay(ruleTimeYear(new Date()), v.notice_list[0].notice_date)
                console.log(a, ruleTimeYear(new Date()))
                if (a <= 2) {
                  v.colorSet = 'red'
                } else if (a > 2) {
                  v.colorSet = 'green'
                }
              } else {
                a = getDiffDay(v.notice_list[0].notice_date, ruleTimeYear(new Date()))
                console.log(a, ruleTimeYear(new Date()), 222)
                if (a <= 1) {
                  v.colorSet = 'red'
                } else {
                  v.colorSet = 'aaa'
                }
              }
            } else {
              v.colorSet = 'aaa'
            }
          })
          console.log('this.listIndex==', this.listIndex)
          if (this.list.length > 0) {
            if (this.listIndex <= this.list.length - 1) {
              this.actPatientData = val.data[this.listIndex]
            } else {
              this.actPatientData = val.data[0]
            }
            this.record_id = this.actPatientData.record_id
            this.group = [this.actPatientData.group]
            this.create.patient_id = this.actPatientData.patient_id
            this.create.schedule_bed_id = this.actPatientData.id
            this.create.bed_id = this.actPatientData.bed_num
            this.create.dict_tonglu = this.actPatientData.dict_tonglu
            // 调取信息
            this.initwar(this.actPatientData)
          } else {
            this.actPatientData = {}
          }
          console.log('this.list==', this.list)
          this.list.forEach((item) => {
            // item.countdown = 5000
            // let h = Math.floor(item.countdown / 3600)
            // let m = Math.ceil(Math.floor(item.countdown % 3600) / 60)
            // let s = item.countdown % 60
            // item.countdownZw = `${h}:${m}:${s}`
            let h = Math.floor(item.countdown / 3600)
            let m = Math.floor((item.countdown / 60) % 60)
            let s = item.countdown % 60
            let hour = h < 10 ? '0' + h : h
            let min = m < 10 ? '0' + m : m
            let sec = s < 10 ? '0' + s : s
            item.countdownZw = `${hour}:${min}:${sec}`
          })
          this.stepsUpdate()
          if (this.is_sign == -1) {
            let nosign = 0
            this.list.forEach((item) => {
              if (item.is_sign === 0) {
                nosign = nosign + 1
              }
            })
            this.statusCount = {
              all: this.list.length,
              sign: this.list.length - nosign,
              nosign: nosign,
            }
          }
        }
      },
      // 获取字典数据
      async getDict() {
        this.dictList = await gitDictList([
          '103000000',
          '119000000',
          '100000000',
          '339400000',
          '339500000',
          '118000000',
          '115000000',
          '114000000',
          '113000000',
          '157000000',
          '176000000',
          '178000000',
          '116000000',
          '179000000',
          '316000000',
          '110000000',
          '134000000',
          '135000000',
          '136000000',
          '350000000',
        ])
      },
      // 透析方式选择
      dialysis_way(val) {
        ElMessageBox.confirm('是否提取' + val.name + '方案信息', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
        })
          .then(async () => {
            if (
              val.name == 'HF' ||
              val.name == 'HDF' ||
              val.name == 'HDF-FX600+HP' ||
              val.name == 'UF+HF' ||
              val.name == 'HP+HF'
            ) {
              this.disabled = false
            } else {
              this.disabled = true
            }
            // const res = await service.post('/api/dialysis_record/init_way', {
            //   patient_id: this.create.patient_id,
            //   dict_dialysis_type: val.code,
            // })
            // if (res.code === 0) {
            //   if (!Array.isArray(res.data)) {
            //     this.create = Object.assign(this.create, res.data)
            //     //this.create.bed_id = this.list[0].bed_num
            //     //this.create.schedule_bed_id = this.actPatientData.id
            //   } else {
            //     ElMessage({
            //       showClose: true,
            //       message: '暂无数据！',
            //       type: 'warning',
            //     })
            //   }
            // }
          })
          .catch(() => { })
      },
      //使用模板添加
      async Usetemplate() {
        const val = await service.post('/api/case_tmpl/list')
        this.tmplist = val.data
        this.tmbleval = true
      },
      // 模板操作按钮
      tmb_btn(row) {
        this.create.condition = this.create.condition + row.tmpl_content + ','
        this.tmbleval = false
      },
      // 查看模板内容
      tmp_tabclick(e) {
        this.tmpl_content = e.tmpl_content
      },
      // 模板取消
      tmb_QX() {
        this.tmbleval = false
      },
      // 模板确认
      async submitForm() {
        if (this.tmb_Index == 1) {
          if (!this.ruleForm.tmpl_content || !this.ruleForm.tmpl_name) {
            this.$message({
              showClose: true,
              message: '请完整输入内容',
              type: 'error',
            })
          } else {
            const val = await service.post('/api/case_tmpl/create', this.ruleForm)
            if (val.code === 0) {
              this.$message({
                message: '添加成功',
                type: 'success',
              })
              this.ruleForm = {}
              this.addtmb = true
              this.Usetemplate()
            }
          }
        } else {
          const data = {
            id: this.tmb_id,
            tmpl_name: this.ruleForm.tmpl_name,
            tmpl_content: this.ruleForm.tmpl_content,
          }
          const res = await service.post('/api/case_tmpl/edit', data)
          if (res.code === 0) {
            this.$message({
              message: '修改成功',
              type: 'success',
            })
            this.addtmb = true
            this.ruleForm = {}
            this.Usetemplate()
          }
        }
      },
      // 计算体重增长率
      async weight(e) {
        console.log(e, this.create.dry_weight)
        if (e && this.create.dry_weight) {
          this.create.befor_raise_weight = (e - this.create.dry_weight).toFixed(2)
          if (this.create.befor_raise_weight == 0 || this.create.dry_weight == 0) {
            this.create.befor_raise_weight_rate = 0
          } else {
            this.create.befor_raise_weight_rate = (this.create.befor_raise_weight / this.create.dry_weight * 100).toFixed(2)
          }

        }
      },
      // 新增模板按钮
      tmb_handebtn() {
        this.addtmb = !this.addtmb
        this.tmb_Index = 1
      },
      // 模板删除
      async tmb_dele(id) {
        const val = await service.post('/api/case_tmpl/delete', { id: id })
        if (val.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.Usetemplate()
        }
      },
      //模板修改
      tmb_alter(row) {
        this.tmb_Index = 2
        this.addtmb = false
        this.tmb_id = row.id
        this.ruleForm.tmpl_name = row.tmpl_name
        this.ruleForm.tmpl_content = row.tmpl_content
      },
      //脱水量计算
      Target_dewatering() {
        if (this.create.befor_weight && this.create.dry_weight) {
          this.create.target_warter = (
            Number(this.create.befor_weight) -
            Number(this.create.dry_weight)
          ).toFixed(2) * 1000
        }
      },
      errorHandler() { },
      // 关于实际体重计算
      Throughweightafter() {
        this.create.befor_clean_weight = (
          Number(this.create.befor_weight) - Number(this.create.add_weight)
        ).toFixed(2)
        // 体重增长
        this.weight(this.create.befor_weight)
        // 脱水量计算
        this.Target_dewatering()
      },
      //关于附加体重的计算
      Theadditionalweight() {
        this.create.befor_clean_weight = (
          Number(this.create.befor_weight) - Number(this.create.add_weight)
        ).toFixed(2)
        // 体重增长
        this.weight(this.create.befor_weight)
      },
      // 床位号选择弹窗
      bed() {
        this.bedval = true
        this.preconval = ''
        this.bidIndex = ''
      },
      // 穿刺信息选择弹窗
      desc() {
        this.puncture = true
        console.log(this.create)
      },
      // 分组选择
      async receptions(index, id) {
        let data = {
          bed_area_id: id,
          current: 1,
          size: 999,
          hospital_area_id: this.sid,
          bed_id: this.create.bed_id,
        }
        const res = await service.post('/api/bed/list', data)
        if (res.code === 0) {
          this.bed_no = res.data.records
        }
        this.receptionIndex = index
      },
      // 床位号选择
      bidno(index, row) {
        this.preconval = row
        this.bidIndex = index
      },
      nameSet(name, list, value) {
        list.forEach(item => {
          if (item.code == value) {
            this.create[name] = item.name
          }
        })
      },
      is_fistulaChange(e) {
        console.log(e)
        this.create.is_fistula = e
      },
      // 穿刺弹窗确认
      Confirmpuncture() {
        if (this.create.is_fistula === 134000000) {
          this.create.chuanci_desc =
            'A:' +
            this.create.dict_a_chuanci_way_name +
            this.create.dict_a_chuanci_zhen_name +
            this.create.dict_a_chuanci_zhen_type_name +
            ',' +
            'V:' +
            this.create.dict_v_chuanci_way_name +
            this.create.dict_v_chuanci_zhen_name +
            this.create.dict_v_chuanci_zhen_type_name
          this.create.dict_chuanci_type_name = ''
          this.create.dict_chuanci_type = ''
        } else {
          this.create.chuanci_desc = this.create.dict_chuanci_type_name
          this.create.dict_a_chuanci_way_name = ''
          this.create.dict_a_chuanci_zhen_name = ''
          this.create.dict_a_chuanci_zhen_type_name = ''
          this.create.dict_v_chuanci_way_name = ''
          this.create.dict_v_chuanci_zhen_name = ''
          this.create.dict_v_chuanci_zhen_type_name = ''
          this.create.dict_a_chuanci_way = ''
          this.create.dict_a_chuanci_zhen = ''
          this.create.dict_a_chuanci_zhen_type = ''
          this.create.dict_v_chuanci_way = ''
          this.create.dict_v_chuanci_zhen = ''
          this.create.dict_v_chuanci_zhen_type = ''
        }
        this.puncture = false
      },
      timeOUt() {
        if (this.list.length > 0) {
          this.list.forEach((item) => {
            if (item.countdown > 0) {
              item.countdown = item.countdown - 1
              let h = Math.floor(item.countdown / 3600)
              let m = Math.floor((item.countdown / 60) % 60)
              let s = item.countdown % 60
              let hour = h < 10 ? '0' + h : h
              let min = m < 10 ? '0' + m : m
              let sec = s < 10 ? '0' + s : s
              item.countdownZw = `${hour}:${min}:${sec}`
              // let h = Math.floor(item.countdown / 3600)
              // let m = Math.ceil(Math.floor(item.countdown % 3600) / 60)
              // let s = item.countdown % 60
              // item.countdownZw = `${h}:${m}:${s}`
            }
          })
        }
      },
      async openRemind(row) {
        console.log('dgrgrgrgr')
        this.modRemind = true
        this.ruleForm1 = row.notice_list
      }
    },
  }
</script>

<style scoped lang="scss">
  .red {
    color: red;
  }

  .green {
    color: green;
  }

  #printArea {
    position: absolute;
    width: 100%;
    z-index: -1;

    .printAreaBox {
      .name {
        text-align: center;
        font-size: 18px;
        color: #000000;
        border-bottom: 2px solid #000000;
        line-height: 40px;
      }

      .time {
        font-size: 12px;
        color: #000;
        margin-top: 10px;
        text-align: right;
      }

      .print-item {
        padding-left: 10px;
        font-size: 12px;
        color: #000;
        margin-top: 10px;

        .pl {
          margin-right: 14px;
        }
      }
    }
  }

  .steps-item {
    border-bottom: 2px solid #999999;
    height: 40px;
    position: relative;
    width: 11.11%;

    &.active {
      border-color: #3166ae;

      .steps-item-node {
        background-color: #3166ae;
      }
    }

    .steps-item-node {
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #999999;

      &.right {
        background-color: #999999;
        left: initial;
        right: 0;

        &.active {
          background-color: #3166ae;
        }
      }
    }
  }

  .hande {
    .el-select {
      width: 124px;
    }

    .el-input {
      width: 144px;
    }

    .el-form-item {
      margin-right: 20px;
    }

    &:deep(.el-form-item__content .el-date-editor.el-input) {
      width: 160px;
    }
  }

  .hande {
    background: #ffffff;
    border-radius: 4px;
    opacity: 1;
    padding: 8px;
    padding-bottom: 0;

    .el-form {
      margin-top: 0;
    }
  }

  .Qiando {
    background: #3166ae !important;
    color: #fff !important;
  }

  .Qiandon {
    width: 80px;
    height: 32px;
    background: #fff;
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #3166ae;
    text-align: center;
    line-height: 32px;
    border: 1px solid rgba(49, 102, 174, 1);
    cursor: pointer;
  }

  .rights {
    display: flex;
    justify-content: space-around;
    width: 104px;
    height: 32px;
    background: #3166ae;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    padding-top: 6px;
    cursor: pointer;
  }

  .icons {
    width: 19px;
    height: 19px;
    background: #ffffff;
    opacity: 1;
    color: rgba(49, 102, 174, 1);
    border-radius: 15px;
    text-align: center;
    padding-top: 1px;
    font-size: 12px;
  }

  .tobys {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .el-input__icon {
    cursor: pointer;
  }

  .bodycliss {
    display: flex;
    margin-top: 2px;
    /* margin-bottom: 70px; */
    height: calc(100% - 100px);
    overflow-y: auto;
  }

  .lifebody {
    width: 216px;
    /* height: 812px; */
    background: #ffffff;
    border-radius: 4px;
    opacity: 1;
    padding: 8px;
    overflow-y: scroll;
  }

  .lifebody::-webkit-scrollbar {
    display: none;
  }

  .zy-bord {
    width: calc(100% - 4px);
    margin-left: 2px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 8px;
    border: 1px solid #eaeff7;
    cursor: pointer;

    .userinfo-item-top {
      height: 40px;
      align-content: center;
      background: #eaeff7;
      border-radius: 6px 6px 0px 0px;
      padding: 8px;

      .nameBox {
        display: flex;
        align-content: center;
      }

      .name {
        font-size: 16px;
        color: #000000;
        margin-right: 8px;
        border-left: 3px solid #3167a8;
        padding-left: 4px;
      }

      .bed_num {
        color: #999999;
      }

      .pcls {
        height: 22px;
        line-height: 22px;
        background: #d4deeb;
        border-radius: 2px;
        font-size: 12px;
        padding: 0 4px;
        color: #3167a8;
        cursor: pointer;
      }
    }

    .userinfo-item-bottom {
      padding: 8px;
      padding-bottom: 2px;

      ._item {
        font-size: 13px;
        color: #444444;
        margin-bottom: 6px;
      }
    }

    .el-divider--horizontal {
      margin: 9px 0;
    }

    .userinfo-item-bottom {
      align-items: center;
    }

    .ell {
      &.mar {
        margin-right: 8px;
      }

      &:deep(.el-tag__content) {
        max-width: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.active {
      border-color: #3167a8;

      .userinfo-item-top {
        background: #3167a8;

        .name {
          color: #fff;
          border-color: #fff;
        }

        .clinicalreception {
          background: #d4deeb;
        }
      }
    }
  }

  .Jiezhen {
    width: 52px;
    height: 24px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #f4a939;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #f4a939;
    text-align: center;
    line-height: 24px;
  }

  .txcis {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    width: 80px;
  }

  .txciss {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    width: 80px;
  }

  .QXbtn {
    width: 80px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #3166ae;
    cursor: pointer;
  }

  .QEbtn {
    width: 80px;
    height: 32px;
    background: #3166ae;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: none;
    color: #fff;
    cursor: pointer;
  }

  .BCboder {
    width: 70px;
    height: 24px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    margin-top: 4px;
  }

  .starclis {
    width: 100px;
    height: 40px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    margin-left: 50px;
  }

  .a7 {
    color: red;
    font-size: 9px;
    margin-right: 2px;
  }

  .inputel {
    width: 222px;
  }

  .righbody {
    width: calc(100% - 226px);
    margin-left: 4px;
    background: #ffffff;
    border-radius: 4px;
    opacity: 1;
    padding: 0 8px 8px 8px;
    overflow-y: scroll;
  }

  .righbody::-webkit-scrollbar {
    display: none;
  }

  .textcliss {
    margin: 3px 10px;
    color: #444444;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
  }

  .butnli {
    width: 80px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #3166ae;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #3166ae;
    text-align: center;
    line-height: 32px;
    margin-right: 10px;
    cursor: pointer;
    overflow: hidden;
  }

  .butnlis {
    width: 80px;
    height: 32px;
    background: rgba(49, 102, 174, 1);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #3166ae;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #fff;
    text-align: center;
    line-height: 32px;
    margin-right: 10px;
    cursor: pointer;
    overflow: hidden;
  }

  .jniop {
    width: 6px;
    height: 18px;
    background: #3166ae;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    margin-top: 4px;
    margin-right: 10px;
  }

  .juliss {
    width: 97%;
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }

  .bodytxet {
    text-align: right;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
  }

  .circie {
    color: #3166ae;
    font-size: 18px;
    margin-top: 2px;
    cursor: pointer;
    margin-left: 11px;
  }

  .inputlis {
    width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    border-right: none;
    outline: none;
    padding-left: 10px;
    color: #666;
    font-size: 14px;
  }

  .KGclis {
    width: 32px;
    height: 32px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #999999;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    position: relative;
    right: 10px;
  }

  .KGcliss {
    width: 32px;
    height: 32px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #999999;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    position: relative;
  }

  .BFbi {
    width: 90px;
    height: 32px;
    position: relative;
    right: 10px;
    border: 1px solid #ccc;
    border-right: none;
    outline: none;
    border-left: none;
    padding-left: 10px;
    color: #666;
    font-size: 14px;
  }

  .BFbiclis {
    width: 32px;
    height: 32px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #999999;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-left: none;
    position: relative;
    right: 15px;
  }

  .divclis {
    width: 200px;
    height: 36px;
    background: #3166ae;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: #fff;
    text-align: center;
    line-height: 36px;
    margin-top: 10px;
    margin-left: 30px;
    cursor: pointer;
  }

  .pclss {
    width: 18px;
    height: 18px;
    background: rgba(255, 62, 62, 0.1);
    opacity: 1;
    color: #ff3e3e;
    border-radius: 10px;
    text-align: center;
    padding-top: 1px;
    cursor: pointer;
  }

  .pclsxs {
    width: 18px;
    height: 18px;
    opacity: 1;
    border-radius: 10px;
    text-align: center;
    padding-top: 1px;
    cursor: pointer;
    background: #ff3e3e;
    color: #fff;
  }

  .Steps {
    //height: 64px;
    background: #ffffff;
    border-radius: 4px;
    opacity: 1;
    margin-bottom: 2px;
    padding: 8px 2%;

    &:deep(.el-steps) {
      .el-step__title {
        font-size: 14px;
        line-height: 32px;
      }
    }
  }

  .fiall {
    display: flex;
    position: fixed;
    bottom: 1%;
    right: 2%;
  }

  .warings {
    width: 80px;
    height: 80px;
    background: #3166ae;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    margin: 0 20px;
    cursor: pointer;
  }

  .waringss {
    width: 80px;
    height: 80px;
    background: rgba(49, 102, 174, 0.1);
    color: #3166ae;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    opacity: 1;
    margin: 0 20px;
    cursor: pointer;
  }

  .waringstext {
    width: 48px;
    height: 40px;
    margin: 18px auto;
  }

  .Close {
    width: 18px;
    height: 18px;
    background: rgba(255, 62, 62, 0.1);
    opacity: 1;
    color: #ff3e3e;
    border-radius: 50%;
    text-align: center;
    margin-right: 20px;
    cursor: pointer;
    margin-top: 5px;
  }

  .clostext {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin-top: 5px;
  }

  .closhand {
    width: 100%;
    display: flex;
    margin: 10px 0;
    height: 100px;
    flex-wrap: wrap;
  }

  .tmb_hande {
    height: 32px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    color: #fff;
    margin: 10px 0;
  }

  .el-iconright {
    width: 18px;
    height: 18px;
    background: #ffffff;
    opacity: 1;
    border-radius: 50%;
    color: #3166ae;
    margin-right: 10px;
    padding-top: 2px;
  }

  .tmb_tab {
    display: flex;
    justify-content: space-around;
  }

  .tmb_btn {
    width: 21px;
    height: 21px;
    background: rgba(104, 71, 236, 0.3);
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #6847ec;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .tmb_btn:hover {
    background: #6847ec;
    color: #fff;
  }

  .tmb_btndele {
    width: 21px;
    height: 21px;
    background: rgba(255, 62, 62, 0.1);
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ff3e3e;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .tmb_btndele:hover {
    background: #ff3e3e;
    color: #fff;
  }

  .tmb_btnalter {
    width: 21px;
    height: 21px;
    background: rgba(49, 102, 174, 0.3);
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #3166ae;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .tmb_btnalter:hover {
    background: #6847ec;
    color: #fff;
  }

  .tmb_body {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    height: 30px;
    line-height: 30px;
    margin: 20px 0;
  }

  .deleiDialysis:hover {
    color: red;
  }

  .deleiDialysis {
    margin: 10px 0;
    cursor: pointer;
  }

  .clinicalreception {
    height: 20px;
    line-height: 18px;
    border-radius: 32px;
    border: 1px solid #f4a939;
    font-size: 10px;
    color: #f4a939;
    padding: 0 4px;
  }

  .bottmcliss {
    height: 160px;
  }

  .hanbuts {
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 230px);
  }

  .headfixed {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
    margin-bottom: 8px;
    // padding-bottom: 10px;
    padding-top: 10px;
    background-color: #fff;
  }

  .Dialysis {
    display: flex;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.12);
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 25px;
  }

  .Thepiercing {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    margin: 10px 0;
  }

  .bidcliss {
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 10px;
    color: #6847ec;
    border: 1px solid #6847ec;
    cursor: pointer;
  }

  .bidclis {
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid #e5e6eb;
    cursor: pointer;
    border-left: 0;
  }

  .recepbody {
    width: 95%;
    margin: 0 auto;
    background-color: rgba(49, 102, 174, 0.1);
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;

    .body_bid {
      width: 110px;
      height: 64px;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;
      padding: 5px 0;
      margin: 5px;
      text-align: center;
    }

    .body_bids {
      width: 110px;
      height: 64px;
      border-radius: 4px;
      color: #fff;
      background-color: #0057bb;
      cursor: pointer;
      padding: 5px 0;
      margin: 5px;
      text-align: center;
    }

    .display_flex {
      display: flex;
      justify-content: space-between;
    }
  }
</style>

<style scoped>
  .demo-ruleForm>>>.el-form-item__label {
    width: 120px !important;
    text-align: right;
  }

  .demo-ruleForm>>>.el-form-item__content {
    width: 251px !important;
    line-height: 29px !important;
  }

  .demo-ruleForm>>>.el-textarea__inner {
    width: 835px !important;
  }

  .el-input-group__append .el-select>>>.el-input__inner {
    border: none !important;
  }

  /* .el-input-group__append .el-select >>> .el-input {
  width: 100px;
  padding-left: 10px;
} */
  .demo-form>>>.el-form-item__label {
    width: 120px !important;
    text-align: right;
  }

  .demo-form>>>.el-select {
    width: 120px;
  }

  .demo-form {
    margin-left: 20px;
  }

  /deep/ .el-form-item {
    margin-bottom: 8px;
  }

  /deep/ .el-form-item__error {
    display: none;
  }

  .info-btn {
    display: inline;
    padding: 2px 8px;
    height: 22px;
    background: #ff3e3e;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
  }

  .notice {
    padding-left: 8px;
    padding-bottom: 9px;
  }
</style>
<style media="print" scoped>
  @page {
    size: auto;
    margin: 3mm;
  }

  /* html {
  background-color: #ffffff;
  height: auto;
  margin: 0px;
} */
</style>
